import React from 'react';
import cn from 'classnames'
import classes from './MenuButton.module.scss';

import menu from '../../../assets/img/menu-button.svg';
import close from '../../../assets/img/menu-close.svg';


const MenuButton = (props) => (
    <div className={ cn(classes.menuButton, {
        [classes.isOpened]: props.isOpened
    }) }>
        <img src={ menu } alt="" className={ classes.menu } onClick={ props.openClicked } />
        <img src={ close } alt="" className={ classes.close } onClick={ props.closeClicked } />
    </div>
)

export default MenuButton;