import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import axios from 'axios';
import classes from './CustomPage.module.scss';
import ReactGA from 'react-ga';
import CallToAction from '../CallToAction/CallToAction';
import * as actions from '../../store/actions';

class CustomPage extends Component {

    state = {
        isFetching: true,
        content: null,
        header: null,
        seoTitle: null,
        seoDescription: null,
        ctaTitle: null,
        ctaText: null
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        axios.get('https://api.engmonsters.in.ua/page/', {
            params: {
                lang: this.props.lang,
                url: this.props.match.params.cutomPageId
            }
        })
        .then(response => {
            if(response.data === 'error 404') {
                this.props.history.push('/404')
            } else {
                this.setState({
                    content: response.data.content,
                    header: response.data.header,
                    seoTitle: response.data.seo_title,
                    seoDescription: response.data.seo_secription,
                    ctaTitle: response.data.call_to_action_header,
                    ctaText: response.data.call_to_action_desc,
                    isFetching: false
                })
            }
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lang !== this.props.lang) {
            axios.get('https://api.engmonsters.in.ua/page/', {
                params: {
                    lang: this.props.lang,
                    url: this.props.match.params.cutomPageId
                }
            })
            .then(response => {
                if(response.data === 'error 404') {
                    this.props.history.push('/404')
                } else {
                    this.setState({
                        content: response.data.content,
                        header: response.data.header,
                        seoTitle: response.data.seo_title,
                        seoDescription: response.data.seo_secription,
                        ctaTitle: response.data.call_to_action_header,
                        ctaText: response.data.call_to_action_desc,
                        isFetching: false
                    })
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    showModal = () => {
        this.props.showModal()
    }

    render () {
        return this.state.isFetching ? null : (
            <>
                <div className={ classes.page }>
                    <Helmet>
                        <title>{ this.state.seoTitle }</title>
                        <meta name="description" content={ this.state.seoDescription } />
                    </Helmet>
                    <div className={ classes.wrapper }>
                        { this.state.header && (
                            <div className={ classes.title }>
                                { this.state.header }
                            </div>
                        ) }
                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.content } }/>
                    </div>
                </div>
                <CallToAction 
                    title={ this.state.ctaTitle }
                    subtitle={ this.state.ctaText }
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomPage);