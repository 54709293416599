import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import cn from 'classnames';
import classes from './TypeSwitch.module.scss';

import arrowIcon from '../../assets/img/type-switch-arrow.svg';
import arrowFooterIcon from '../../assets/img/type-switch-arrow-footer.svg';
import dropdown from '../../assets/img/type-switch-dropdown.svg';
import separator from '../../assets/img/type-switch-line.svg'
import pinIcon from '../../assets/img/pin.svg'
import pinFooterIcon from '../../assets/img/pin-footer.svg'

class TypeSwitch extends Component {

    state = {
        opened: false
    }

    switchType = (type) => {
        this.props.switchType(type);
    }

    render() {

        let label
        let kyivLabel = this.props.lang === 'ukr' ? 'Київ' : 'Киев';
        let onlineLabel = 'Онлайн'
        let arrow = this.props.inFooter ? arrowFooterIcon : arrowIcon;
        let pin = this.props.inFooter ? pinFooterIcon : pinIcon;


        if(this.props.type === 'kyiv') {
            label = kyivLabel
        } else if(this.props.type === 'online'){
            label = onlineLabel
        }

        return(
            <div className={ cn(classes.container, {
                [classes.inFooter]: this.props.inFooter,
                [classes.inHeader]: this.props.inHeader,
                [classes.inMenu]: this.props.inMenu
            }) }>
                <div className={ cn(classes.control, {
                    [classes.white]: this.props.inFooter
                }) }>
                    <img src={ pin } alt="" className={ classes.pin } />
                    { label }
                    <img src={ arrow } alt="" className={ classes.arrow } />
                </div>
                <div className={ classes.dropdown }>
                    <img src={ dropdown } alt="" className={ classes.dropdownBackground } />
                    <div className={ classes.dropdownInner }>
                        <div className={ classes.option } onClick={ () => this.switchType('kyiv') }>
                            { kyivLabel }
                        </div>
                        <img src={ separator } alt="" />
                        <div className={ classes.option } onClick={ () => this.switchType('online') }>
                            { onlineLabel }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang,
        type: state.basic.type
    }
}

const mapDispatchToProps = dispatch => {
    return{
        switchType: (type) => dispatch(actions.switchType(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeSwitch);