import React, { Component } from 'react';
import classes from './HowWeWork.module.scss';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import SvgLines from 'react-mt-svg-lines';

import jimCarrey from '../../../assets/img/jim-carrey.png';
import jimEllipse from '../../../assets/img/jim-ellipse.svg';
import jimBarbell from '../../../assets/img/jim-barbell.svg'
import jimBandana from '../../../assets/img/jim-bandana.svg'

import chameleon from '../../../assets/img/chameleon.png';
import chameleonHand from '../../../assets/img/chameleon-hand.png';
import chameleonStick from '../../../assets/img/chameleon-stick.svg';
import chameleonSkull from '../../../assets/img/chameleon-skull.svg';
import chameleonCloud from '../../../assets/img/chameleon-cloud.svg';

import remote from '../../../assets/img/remote.png';
import remoteBg from '../../../assets/img/remote-bg.svg';

import pizza from '../../../assets/img/pizza.png';
import pizzaFlag from '../../../assets/img/pizza-flag.svg';
import pizzaDrips from '../../../assets/img/pizza-drips.svg';
import pizzaHam1 from '../../../assets/img/pizza-ham-1.svg';
import pizzaHam2 from '../../../assets/img/pizza-ham-2.svg';
import pizzaHam3 from '../../../assets/img/pizza-ham-3.svg';
import pizzaHam4 from '../../../assets/img/pizza-ham-4.svg';

import bike from '../../../assets/img/bike.png';
import bikeBg from '../../../assets/img/bike-bg.svg';
import bikeCloud from '../../../assets/img/bike-cloud.svg';
import bikeCrown from '../../../assets/img/bike-crown.svg';

import salt from '../../../assets/img/salt.png';
import saltBg from '../../../assets/img/salt-bg.svg';
import saltLabel from '../../../assets/img/salt-label.svg';

class HowWeWork extends Component {
    
    state = {
        isFirstInvisible: true,
        isSecondInvisible: true,
        isThirdInvisible: true,
        isFourthInvisible: true,
        isFifthInvisible: true,
        isSixInvisible: true
    }

    makeFirstMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFirstInvisible: false
            })
        }
    }

    makeSecondMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isSecondInvisible: false
            })
        }
    }

    makeThirdMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isThirdInvisible: false
            })
        }
    }

    makeFourthMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFourthInvisible: false
            })
        }
    }

    makeFifthMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFifthInvisible: false
            })
        }
    }

    makeSixMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isSixInvisible: false
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.type !== this.props.type) {
            this.setState({
                isFirstInvisible: true,
                isSecondInvisible: true,
                isThirdInvisible: true,
                isFourthInvisible: true,
                isFifthInvisible: true,
                isSixInvisible: true
            })
        }
    }

    render() {
        return(
            <div className={ classes.wrapper }>
                <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFirstInvisible } onChange={ this.makeFirstMethodVisible } >
                    {
                        ({ isVisible }) => 
                        <div className={ cn(classes.method , {
                            [classes.visible]: isVisible
                        }) }>
                            <div className={ classes.image }>
                                <img className={ classes.jimCarrey } src={ jimCarrey } alt="" />
                                <img className={ classes.jimEllipse } src={ jimEllipse } alt="" />
                                <img className={ classes.jimBarbell } src={ jimBarbell } alt="" />
                                <img className={ classes.jimBandana } src={ jimBandana } alt="" />
                                <div className={ classes.jimText }>GYM - Джим кеРрі</div>
                            </div>
                            <div className={ classes.info }>
                                <div className={ cn(classes.num, classes.green) }>
                                    Метод 1
                                </div>
                                <div className={ classes.title }>
                                    { this.props.methods.title1 }
                                </div>
                                <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc1 } } />
                            </div>
                            { isVisible && <SvgLines stagger={ 50 } timing='ease-in' fade={ true } animate={ 300 } duration={ 1500 } className={ classes.firstPath}>
                                <svg width="774" height="68" viewBox="0 0 774 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4.5C9.5 22.5 23.5 45.5 57 51.5" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M90.5 58.5C103.5 62.0416 106.6 62.0416 117.5 62.0416C121.032 62.0416 137 62.0416 151 62.0416" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M185.341 61.4226C203.295 61.4226 221.249 61.4226 239.204 61.4226" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M283.161 61.0415C298.123 61.0415 296.038 61.0415 311 61.0415C314.659 61.0415 318.095 62.4226 330 61.0415" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M371.932 59.8983C378.739 59.8983 390.344 60.354 397.406 58.5964C404.468 56.8389 411.254 56.5316 416 50" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M432 41.9155C440.138 34.0166 470.741 26.6044 483.241 41.9154" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M505.324 51.9621C516.173 54.9718 536.204 55.372 546.675 52.5378" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M564 41.3353C574.27 35.3642 586.763 26.1047 604.052 22" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M622.5 16.5C633.922 11.1534 666 6 685 6" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M710.5 8C739 8 760.5 24.5 769.5 35.5" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </SvgLines> }
                        </div>
                    }
                </VisibilitySensor>
                <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isSecondInvisible } onChange={ this.makeSecondMethodVisible }>
                    { ({ isVisible }) =>
                        <div className={ cn(classes.methodEven, {
                            [classes.visible]: isVisible
                        }) }>
                            <div className={ classes.image }>
                                <img src={ chameleon } alt="" className={ classes.chameleon } />
                                <img src={ chameleonHand } alt="" className={ classes.chameleonHand } />
                                <img src={ chameleonSkull } alt="" className={ classes.chameleonSkull } />
                                <img src={ chameleonStick } alt="" className={ classes.chameleonStick } />
                                <img src={ chameleonCloud } alt="" className={ classes.chameleonCloud } />
                                <div className={ classes.chameleonCloudText }>To be чи не to be</div>
                            </div>
                            <div className={ classes.info }>
                                <div className={ cn(classes.num, classes.red) }>Метод 2</div>
                                <div className={ classes.title }>
                                    { this.props.methods.title2 }
                                </div>
                                <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc2 } } />
                            </div>
                            { isVisible && <SvgLines stagger={ 50 } timing='ease-in' fade={ true } animate={ 320 } duration={ 1500 } className={ classes.secondPath}>
                                <svg width="787" height="130" viewBox="0 0 787 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M768.777 5.99988C767.19 24.6004 768.777 32.4998 754.611 50.4998" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M743.566 68.6673C736.566 79.667 726.566 87.6671 713.881 92.8379" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M692.145 104.049C681.758 110.357 666.85 111.428 650.566 108.667" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M622.292 101.915C618.436 101.026 608.119 99.5954 601.567 95.0453" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M575.834 82.9553C560.787 74.96 545.011 68.7424 530 62.4517" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M503.07 51.4716C488.331 46.5384 474.162 44.7714 459.318 48.8274" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M436.625 51.6957C420.985 65.8034 392.986 56.5183 375.146 50.0297" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M353.293 39.1197C343.535 34.1578 333.043 32.1473 322.3 30.7028C317.1 30.0036 310.761 29.3101 304 31.4204" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M278.412 38.7234C267.422 43.8721 261.977 44.5923 250.463 44.2983C245.433 44.1698 235.303 44.0828 231.483 41.0625" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M203.971 37.8788C197.495 36.0361 191.216 35.8666 184.908 33.79" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M164.523 31.1987C157.832 31.5343 126.524 36.0575 120.168 38.3271" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M97.5034 46.7715C85.9791 51.4957 66.7934 57.374 51.6974 50.7586" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M30.6907 48.0832C19.5571 45.6243 13.1179 47.9699 4.0065 51.5761" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </SvgLines> }
                        </div>
                    }
                </VisibilitySensor>
                <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isThirdInvisible } onChange={ this.makeThirdMethodVisible }>
                    { ({ isVisible }) =>
                        <div className={ cn(classes.method , {
                            [classes.visible]: isVisible
                        }) }>
                            <div className={ classes.image }>
                                <img src={ remote } alt="" className={ classes.remote } />
                                <img src={ remoteBg } alt="" className={ classes.remoteBg } />
                                <div className={ classes.remoteWords }>
                                    <div className={ classes.word }>гав!</div>
                                    <div className={ classes.word }>гав!</div>
                                    <div className={ classes.word }>гав!</div>
                                    <div className={ classes.word }>гав!</div>
                                </div>
                            </div>
                            <div className={ classes.info }>
                                <div className={ cn(classes.num, classes.yellow) }>Метод 3</div>
                                <div className={ classes.title }>
                                    { this.props.methods.title3 }
                                </div>
                                <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc3 } } />
                            </div>
                            { isVisible && <SvgLines stagger={ 50 } timing='ease-in' fade={ true } animate={ 340 } duration={ 1500 } className={ classes.thirdPath}>
                                <svg width="793" height="154" viewBox="0 0 793 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.8672 33.9998C25.3693 41.9838 31.6538 53.2189 29.1709 64.8589" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M30.7729 88.5752C28.6113 99.4645 33.1167 115.487 44.82 120.602" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M68.8093 132.166C79.1806 137.187 86.2024 136.447 96.4292 135.23C102.096 134.555 108.587 132.197 115.522 128.71" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M129.247 109.913C134.107 98.7922 137.723 94.6586 146.569 87.2814C150.433 84.0589 158.101 77.4403 162.965 77.1891" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M186.184 66.2015C195.835 61.0356 206.283 58.8051 216.993 57.1351C222.177 56.3268 228.5 55.5002 235.304 57.468" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M264.241 60.0766C283.741 68.0389 306.927 49.8024 321.506 37.6444" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M343.04 24.156C352.672 16.8374 366.147 9.27231 377.387 17.5691" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M400.415 29.9903C410.974 37.7855 421.178 44.8393 434.911 43.1703C442.646 42.2303 453.182 41.0156 459.374 36.6585" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M481 24.5884C495.948 20.3312 510.183 19.2113 524.827 23.939" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M549.982 31.4068C564.237 39.1657 572.851 43.6036 593.739 38.7307" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M622.774 30.5884C637.722 26.3312 651.957 25.2113 666.601 29.939" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M696.73 29.8604C709.845 30.3665 722.219 31.229 731.903 41.1089C737.357 46.6731 744.828 54.2012 746.943 61.4714" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M755.249 82.9999C761.547 94.3136 769.395 112.5 774.895 134" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </SvgLines> }
                        </div>
                    }
                </VisibilitySensor>
                { this.props.type === 'kyiv' ? (
                    <>
                        <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFourthInvisible } onChange={ this.makeFourthMethodVisible }>
                            { ({ isVisible }) =>
                                <div className={ cn(classes.methodEven, {
                                    [classes.visible]: isVisible
                                }) }>
                                    <div className={ classes.image }>
                                        <img src={ pizza } alt="" className={ classes.pizza } />
                                        <img src={ pizzaFlag } alt="" className={ classes.pizzaFlag } />
                                        <img src={ pizzaDrips } alt="" className={ classes.pizzaDrips } />
                                        <img src={ pizzaHam1 } alt="" className={ classes.pizzaHam1 } />
                                        <img src={ pizzaHam2 } alt="" className={ classes.pizzaHam2 } />
                                        <img src={ pizzaHam3 } alt="" className={ classes.pizzaHam3 } />
                                        <img src={ pizzaHam4 } alt="" className={ classes.pizzaHam4 } />
                                    </div>
                                    <div className={ classes.info }>
                                        <div className={ cn(classes.num, classes.green) }>Метод 4</div>
                                        <div className={ classes.title }>
                                            { this.props.methods.title4 }
                                        </div>
                                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc4 } } />
                                    </div>
                                    { isVisible && <SvgLines stagger={ 50 } timing='ease-in' fade={ true } animate={ 360 } duration={ 1500 } className={ classes.fourthPath}>
                                        <svg width="720" height="77" viewBox="0 0 720 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M714.208 22.864C710.666 34.4893 710.343 40.6112 692.155 49.7568" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M663.284 57.4548C659.377 58.083 649.281 60.6405 641.499 58.8907" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M617.181 57.7515C600.231 56.0074 583.275 56.1847 567 56.0063" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M544.416 53.5905C537.766 52.5405 531.512 53.1279 525 51.8255" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M499.955 46.235C488.19 42.1476 468.712 37.3265 454 44.7569" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M434.061 59.7805C423.852 65.9855 415.481 66.6536 403.116 63.6368" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M377.068 52.0763C368.058 44.2304 359.922 42.1532 347.224 43.0305" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M318.504 46.4153C305.596 47.4448 289.658 47.4442 279 39.5995" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M251.601 27.5884C236.653 23.3312 222.418 22.2113 207.774 26.939" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M179.684 35.1864C163.749 38.2619 154.195 39.8812 135.765 28.9088" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M109.827 21.5884C94.8793 17.3312 80.6437 16.2113 65.9999 20.939" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M43.0956 28.3462C23.9925 33.5971 13.6263 46.589 10.9298 62.7808" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </SvgLines> }
                                </div>
                            }    
                        </VisibilitySensor>   
                        <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFifthInvisible } onChange={ this.makeFifthMethodVisible }>
                            { ({ isVisible }) =>
                                <div 
                                    className={ cn(classes.method , {
                                        [classes.visible]: isVisible
                                    }) }
                                    style={ { marginBottom: 90 } }
                                >
                                    <div className={ classes.image }>
                                        <img className={ classes.bike } alt="" src={ bike } />
                                        <img className={ classes.bikeBg } alt="" src={ bikeBg } />
                                        <img className={ classes.bikeCrown } alt="" src={ bikeCrown } />
                                        <img className={ classes.bikeCloud } alt="" src={ bikeCloud } />
                                        <div className={ classes.bikeText }>Мчу на урок</div>
                                    </div>
                                    <div className={ classes.info }>
                                        <div className={ cn(classes.num, classes.red) }>Метод 5</div>
                                        <div className={ classes.title }>
                                            { this.props.methods.title5 }
                                        </div>
                                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc5 } } />
                                    </div>
                                    { isVisible && <SvgLines stagger={ 50 } timing='ease-in' fade={ true } animate={ 380 } duration={ 2000 } className={ classes.fifthPath}>
                                        <svg width="777" height="137" viewBox="0 0 777 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.9625 45.6037C11.9286 56.5187 7.77735 55.2375 23.4436 67.3955" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M42.4519 86.0548C57.4988 94.05 73.2752 100.268 88.2862 106.558" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M111.686 114.911C126.633 119.168 140.869 120.288 155.513 115.56" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M183.055 102.544C191.078 101.75 209.042 90.01 213.578 85.3987" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M238.567 62.7358C252.413 50.8303 263.632 49.6539 274.345 58.135" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M293 74.5959C301.821 81.3375 318.188 84.3586 327.939 76.1098" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M351.065 68.5879C366.013 64.3307 380.248 63.2108 394.892 67.9385" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M417.807 78.0996C434.071 83.181 449.584 90.0307 464.61 96.2849" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M486.957 104.89C497.789 106.475 508.374 105.028 519.015 102.966C524.166 101.967 530.395 100.599 536.127 96.4399" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M561.271 86.2348C572.259 81.0814 576.295 77.3574 583.435 68.319C586.554 64.3708 592.968 56.5296 593.09 51.6613" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M614.403 29.7605C622.672 21.7349 629.57 20.2314 639.662 18.1734C645.253 17.033 652.156 17.2321 659.837 18.3636" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M685.749 23.7321C696.763 27.072 706.608 32.1229 717.334 35.3757" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M739.219 47.0845C749.433 51.435 760.13 64.186 757.761 76.7365" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M754 100.456C755.452 112.782 761.387 124.206 772.478 128.525" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </SvgLines> }
                                </div>
                            }
                        </VisibilitySensor>
                        <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isSixInvisible } onChange={ this.makeSixMethodVisible }>
                            { ({ isVisible }) =>
                                <div className={ cn(classes.methodEven, {
                                    [classes.visible]: isVisible
                                }) }>
                                    <div className={ classes.image }>
                                        <img src={ salt } alt="" className={ classes.salt } />
                                        <img src={ saltBg } alt="" className={ classes.saltBg } />
                                        <img src={ saltLabel } alt="" className={ classes.saltLabel } />
                                        <div className={ classes.saltText }>Стрес</div>
                                    </div>
                                    <div className={ classes.info }>
                                        <div className={ cn(classes.num, classes.yellow) }>Метод 6</div>
                                        <div className={ classes.title }>
                                            { this.props.methods.title6 }
                                        </div>
                                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc6 } } />
                                    </div>
                                </div>
                            }
                        </VisibilitySensor>
                    </>
                ) : (
                    <>
                        <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFourthInvisible } onChange={ this.makeFourthMethodVisible }>
                            { ({ isVisible }) =>
                                <div className={ cn(classes.methodEven, {
                                    [classes.visible]: isVisible
                                }) }>
                                    <div className={ classes.image }>
                                        <img className={ classes.bike } alt="" src={ bike } />
                                        <img className={ classes.bikeBg } alt="" src={ bikeBg } />
                                        <img className={ classes.bikeCrown } alt="" src={ bikeCrown } />
                                        <img className={ classes.bikeCloud } alt="" src={ bikeCloud } />
                                        <div className={ classes.bikeText }>Мчу на урок</div>
                                    </div>
                                    <div className={ classes.info }>
                                        <div className={ cn(classes.num, classes.green) }>Метод 4</div>
                                        <div className={ classes.title }>
                                            { this.props.methods.title5 }
                                        </div>
                                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc5 } } />
                                    </div>
                                    { isVisible && <SvgLines stagger={ 50 } timing='ease-in' fade={ true } animate={ 360 } duration={ 1500 } className={ classes.fourthPath}>
                                        <svg width="720" height="77" viewBox="0 0 720 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M714.208 22.864C710.666 34.4893 710.343 40.6112 692.155 49.7568" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M663.284 57.4548C659.377 58.083 649.281 60.6405 641.499 58.8907" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M617.181 57.7515C600.231 56.0074 583.275 56.1847 567 56.0063" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M544.416 53.5905C537.766 52.5405 531.512 53.1279 525 51.8255" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M499.955 46.235C488.19 42.1476 468.712 37.3265 454 44.7569" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M434.061 59.7805C423.852 65.9855 415.481 66.6536 403.116 63.6368" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M377.068 52.0763C368.058 44.2304 359.922 42.1532 347.224 43.0305" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M318.504 46.4153C305.596 47.4448 289.658 47.4442 279 39.5995" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M251.601 27.5884C236.653 23.3312 222.418 22.2113 207.774 26.939" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M179.684 35.1864C163.749 38.2619 154.195 39.8812 135.765 28.9088" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M109.827 21.5884C94.8793 17.3312 80.6437 16.2113 65.9999 20.939" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M43.0956 28.3462C23.9925 33.5971 13.6263 46.589 10.9298 62.7808" stroke="#EEEEEE" strokeWidth="8" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </SvgLines> }
                                </div>
                            }    
                        </VisibilitySensor>   
                        <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFifthInvisible } onChange={ this.makeFifthMethodVisible }>
                            { ({ isVisible }) =>
                                <div 
                                    className={ cn(classes.method , {
                                        [classes.visible]: isVisible
                                    }) }
                                    style={ { marginBottom: 90 } }
                                >
                                    <div className={ classes.image }>
                                        <img src={ salt } alt="" className={ classes.salt } />
                                        <img src={ saltBg } alt="" className={ classes.saltBg } />
                                        <img src={ saltLabel } alt="" className={ classes.saltLabel } />
                                        <div className={ classes.saltText }>Стрес</div>
                                    </div>
                                    <div className={ classes.info }>
                                        <div className={ cn(classes.num, classes.red) }>Метод 5</div>
                                        <div className={ classes.title }>
                                            { this.props.methods.title6 }
                                        </div>
                                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.props.methods.desc6 } } />
                                    </div>
                                </div>
                            }
                        </VisibilitySensor>
                    </>
                ) }
            </div>
        )
    }
}

export default HowWeWork;
