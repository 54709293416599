import React, { Component } from 'react';
import classes from './FaqSection.module.scss';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import FaqItem from '../FaqItem/FaqItem';

class FaqSection extends Component {

    state = {
        isInvisible: true,
    }

    makeContentVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isInvisible: false
            })
        }
    }


    render() {

        const content = this.props.items.map((item, index) => (
            <FaqItem
                key={ index } 
                question={ item.question }
                answer={ item.answer }
            />
        ))

        return (
            <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isInvisible } onChange={ this.makeContentVisible } >
                {
                    ({ isVisible }) => 
                    <div className={ cn(classes.section, {
                        [classes.visible]: isVisible
                    }) }>
                        <div className={ classes.sectionNum }>Блок { this.props.index + 1 }</div>
                        <div className={ classes.title }>{ this.props.title }</div>
                        <div className={ classes.content }>
                            { content }
                        </div>
                    </div>
                }
            </VisibilitySensor>
        )
    }
}

export default FaqSection;
