import React, { Component } from 'react';
import classes from './FaqItem.module.scss';
import cn from 'classnames';
import arrow from '../../../assets/img/faq-arrow.svg';

class FaqItem extends Component {

    state = {
        opened: false
    }

    toogleState = () => {
        this.setState((prevState) => {
			return {opened: !prevState.opened}
        })
    }

    render () {
        return (
            <div className={ cn(classes.faqItem, {
                [classes.opened]: this.state.opened
            }) } onClick={ this.toogleState }>
                <div className={ classes.question }>
                    <img src={ arrow } alt="" className={ classes.arrow } />
                    { this.props.question }
                </div>
                <div className={ classes.answer } dangerouslySetInnerHTML={ { __html: this.props.answer } } />
            </div>
        )
    }
}

export default FaqItem;