import * as actionTypes from '../actions/actionTypes';

const initialState = {
    lang: 'ukr',
    type: 'kyiv',
    isModalVisible: false,
    modalType: 'solo',
    productId: null
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actionTypes.SWITCH_LANG:
            return {
                ...state,
                lang: action.selectedLang
            }
        case actionTypes.TYPE_SWITCH:
            return {
                ...state,
                type: action.selectedType
            }
        case actionTypes.SHOW_MODAL:
            return {
                ...state,
                isModalVisible: true,
                modalType: 'solo'
            }
        case actionTypes.SHOW_CORPORATE_MODAL:
            return {
                ...state,
                isModalVisible: true,
                modalType: 'corporate'
            }
        case actionTypes.SHOW_PRODUCT_MODAL:
            return {
                ...state,
                isModalVisible: true,
                modalType: 'shop',
                productId: action.id
            }
        case actionTypes.HIDE_MODAL:
            return {
                ...state,
                isModalVisible: false
            }
        default:
            return state;
    }
}

export default reducer;