import React from 'react';
import classes from './SliderButton.module.scss';
import cn from 'classnames';
import sliderBorder from '../../assets/img/slider-button-container.svg';

const SliderButton = (props) => {
    return (
        <div className={ classes.sliderButtonOuter }>
            <div className={ classes.sliderButton }>
                <img src={sliderBorder} alt="" className={ classes.sliderBorder } />
                <div 
                    className={ cn(classes.leftBtnText, {
                        [classes.active]: props.selectedType === props.servicesTypes.individual
                    }) }
                    onClick={ () => props.onTypeToggle(props.servicesTypes.individual) }
                >
                    Для вас
                </div>
                <div 
                    className={ cn(classes.rightBtnText, {
                        [classes.active]: props.selectedType === props.servicesTypes.corporate
                    }) }
                    onClick={ () => props.onTypeToggle(props.servicesTypes.corporate) }
                >
                    { props.lang === 'ukr' ? 'Для компанії' : 'Для компании'}
                </div>
                <svg 
                    width="164" 
                    height="64" 
                    viewBox="0 0 164 64" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                    className={ cn(classes.leftBtn, {
                        [classes.active]: props.selectedType === props.servicesTypes.individual
                    }) }
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M86.8848 2.98209e-10C17.377 5.59704e-05 0 5.11931 0 32.474C0 60.4 28.9616 64 86.8848 64C144.808 64 164.357 56.1731 163.995 32.0258C163.633 7.87853 156.393 -5.59698e-05 86.8848 2.98209e-10Z" fill="#F55428"/>
                </svg>
                <svg 
                    width="233" 
                    height="64" 
                    viewBox="0 0 233 64" 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg" 
                    className={ cn(classes.rightBtn, {
                        [classes.active]: props.selectedType === props.servicesTypes.corporate
                    }) }
                >
                    <path fillRule="evenodd" clipRule="evenodd" d="M109.56 -0.00024414C208.312 -0.00018817 233 5.11906 233 32.4738C233 63.9998 191.853 63.9998 109.56 63.9998C27.2666 63.9998 -0.507339 56.1729 0.00698853 32.0256C0.521317 7.87829 10.808 -0.00030011 109.56 -0.00024414Z" fill="#F55428"/>
                </svg>
            </div>
        </div>
    )
}

export default SliderButton;