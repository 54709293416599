import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Footer.module.scss';
import cn from 'classnames';
import logo from '../../assets/img/logo-white.svg';
import Button from '../Button/Button';
import TypeSwitch from '../TypeSwitch/TypeSwitch';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';

const Footer = (props) => {

    const servicesLabel = props.lang === 'ukr' ? 'Послуги' : 'Услуги'
    const calendarLabel = props.lang === 'ukr' ? 'Календар' : 'Календарь'
    const monstersLabel = props.lang === 'ukr' ? 'Монстри' : 'Монстры'
    const socialLabel = props.lang === 'ukr' ? 'Соціальні мережі' : 'Социальные сети'
    
    return (
        <div className={ cn(classes.footer, {
            [classes.inCallToAction]: props.inCallToAction
        }) }>
            <div className={ classes.wrapper }>
                <div className={ classes.footerTopContainer }>
                    <Link to="/" className={ classes.logo }>
                        <img src={ logo } alt="" />
                    </Link>
                    <div className={ classes.footerLinks }>
                        <div className={ classes.links }>
                            <Link to="/methods" className={ classes.link }>Методика</Link>
                            <Link to="/services" className={ classes.link }>{ servicesLabel }</Link>
                            <Link to="/calendar" className={ classes.link }>{ calendarLabel }</Link>
                            <Link to="/monsters" className={ classes.link }>{ monstersLabel }</Link>
                        </div>
                        <div className={ classes.links }>
                            <Link to="/blog/all" className={ classes.link }>Блог</Link>
                            {/* <Link to="/faq" className={ classes.link }>FAQ</Link> */}
                            <Link to="/shop" className={ classes.link }>Мерч</Link>
                            <Link to="/hiring" className={ classes.link }>We are hiring</Link>
                        </div>
                    </div>
                </div>
                <div className={ classes.foooterBottomContainer }>
                    <div className={ classes.social }>
                        <span className={ classes.socialText }>{ socialLabel }:</span>
                        <div className={ classes.socialButtons }>
                        <Button inFooter facebook />
                        <Button inFooter youtube />
                        <Button inFooter instagram />
                        <Button inFooter telegram />
                        </div>
                    </div>
                    <div className={ classes.switches }>
                        <TypeSwitch inFooter />
                        <LanguageSwitch inFooter />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;