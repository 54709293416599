import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import classes from './Category.module.scss';
import bgShort from '../../../assets/img/category-active-short.svg';
import bgMedium from '../../../assets/img/category-active-medium.svg';
import bgLong from '../../../assets/img/category-active-long.svg';
import bgExtralong from '../../../assets/img/category-active-extralong.svg';
import borderShort from '../../../assets/img/category-border-short.svg';
import borderMedium from '../../../assets/img/category-border-medium.svg';
import borderLong from '../../../assets/img/category-border-long.svg';
import borderExtralong from '../../../assets/img/category-border-extralong.svg';

const Category = (props) => {

    const getProperBackground = (name) => {
        if(props.active) {
            if(name.length < 5) {
                return bgShort
            } 
            if(name.length < 8) {
                return bgMedium
            }
            if(name.length < 14) {
                return bgLong
            }
            return bgExtralong
        }
        if(!props.active) {
            if(name.length < 5) {
                return borderShort
            } 
            if(name.length < 8) {
                return borderMedium
            }
            if(name.length < 14) {
                return borderLong
            }
            return borderExtralong
        }
    }

    return(
        <Link 
            to={ `/blog/${ props.url }` }
            className={ cn(classes.category, {
                [classes.active]: props.active
            }) }
        >
            <img src={ props.name && getProperBackground(props.name) } alt="" className={ classes.border } />
            { props.name }
        </Link>
    )
}

export default Category;