import React from 'react';
import classes from './TopButton.module.scss';

const TopButton = (props) => {
    return (
        <div className={ classes.button } onClick={ props.clicked }>
            <svg width="87" height="80" viewBox="0 0 87 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                <path d="M85.7694 43.8552C85.9103 62.3168 53.5899 79.4493 35.1291 79.5902C14.8961 79.7446 6.93281 64.0461 1.44763 46.4176C-6.11389 22.1162 25.2362 0.459483 43.6971 0.318579C70.6986 0.112489 85.6285 25.3936 85.7694 43.8552Z" />
            </svg>
            <svg width="87" height="80" viewBox="0 0 87 80" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                <path d="M85.7694 43.8552C85.9103 62.3168 53.5899 79.4493 35.1291 79.5902C14.8961 79.7446 6.93281 64.0461 1.44763 46.4176C-6.11389 22.1162 25.2362 0.459483 43.6971 0.318579C70.6986 0.112489 85.6285 25.3936 85.7694 43.8552Z" />
            </svg>
            <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.arrow }>
                <path d="M4.97656 11.2969C5.3151 10.5156 5.71875 9.64323 6.1875 8.67969C6.65625 7.74219 7.16406 6.80469 7.71094 5.86719C8.7526 4.1224 9.89844 2.65104 11.1484 1.45312C12.0339 0.619791 12.9062 0.216145 13.7656 0.242186C14.1562 0.242186 14.5469 0.320311 14.9375 0.476561C15.224 0.60677 15.4714 0.76302 15.6797 0.945311C15.888 1.1276 16.0703 1.33594 16.2266 1.57031C16.7734 1.98698 17.3464 2.52083 17.9453 3.17187C18.5443 3.79687 19.1302 4.48698 19.7031 5.24219C20.7969 6.64844 21.8255 8.09375 22.7891 9.57812C23.7526 11.0885 24.5078 12.2995 25.0547 13.2109C25.1589 13.3932 25.263 13.5625 25.3672 13.7187C25.4714 13.875 25.5495 14.0182 25.6016 14.1484C27.138 16.5703 28.1927 18.6536 28.7656 20.3984C28.9219 20.9193 29 21.388 29 21.8047C29 22.8464 28.5182 23.6406 27.5547 24.1875C26.3568 24.8906 25.0677 24.7083 23.6875 23.6406C22.3073 22.5729 21.0182 21.2318 19.8203 19.6172C19.1693 18.7578 18.5573 17.8854 17.9844 17C17.4115 16.0885 16.9167 15.2682 16.5 14.5391C16.2135 14.0703 15.888 13.5755 15.5234 13.0547C15.1328 12.5339 14.7422 12 14.3516 11.4531C14.2734 11.375 14.2083 11.2969 14.1562 11.2187C14.1042 11.1406 14.0391 11.0625 13.9609 10.9844C13.9089 11.0885 13.8568 11.1927 13.8047 11.2969C13.7526 11.4271 13.7005 11.5573 13.6484 11.6875C13.2578 12.4948 12.75 13.5365 12.125 14.8125C11.5 16.0885 10.8229 17.3776 10.0937 18.6797C9.33854 19.9557 8.57031 21.1536 7.78906 22.2734C6.98177 23.3932 6.1875 24.2266 5.40625 24.7734C5.01562 25.0859 4.625 25.2943 4.23437 25.3984C4.07812 25.4245 3.92187 25.4375 3.76562 25.4375C3.53125 25.4375 3.3099 25.3854 3.10156 25.2812C2.21615 24.8906 1.5651 24.4349 1.14844 23.9141C0.705729 23.3672 0.432291 22.7943 0.328125 22.1953C0.302083 21.9349 0.276041 21.6745 0.25 21.4141C0.25 20.5026 0.510416 19.526 1.03125 18.4844C1.68229 17.1562 2.39844 15.8542 3.17969 14.5781C3.51823 13.9792 3.84375 13.4062 4.15625 12.8594C4.49479 12.3125 4.76823 11.7917 4.97656 11.2969Z" fill="white"/>
            </svg>

        </div>
    )
}

export default TopButton;