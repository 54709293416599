import React, { Component } from 'react';
import classes from './WhatWeCan.module.scss';
import Button from '../../Button/Button';
import individualIcon from '../../../assets/img/individual.svg';
import groupIcon from '../../../assets/img/group.svg';
import corporateIcon from '../../../assets/img/corporate.svg';
import clockIcon from '../../../assets/img/clock-icon.svg';
import speakingClubIcon from '../../../assets/img/speaking-club.svg';
import workshopsIcon from '../../../assets/img/workshops.svg';
import online from '../../../assets/img/online-badge.svg';
import offline from '../../../assets/img/offline-badge.svg';
import VisibilitySensor from 'react-visibility-sensor';
import cn from 'classnames';

class WhatWeCan extends Component {

   state = {
        isInvisible: true,
    }

    makeContentVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isInvisible: false
            })
        }
    }

    render() {
        const onlineBadge = (
            <div className={ classes.online }>
                <img src={ online } alt="" />
                Online
            </div>
        )
        
        const offlineBadge = (
            <div className={ classes.offline }>
                <img src={ offline } alt="" />
                Offline
            </div>
        )
    
        return(
            <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isInvisible } onChange={ this.makeContentVisible } >
                { ({ isVisible }) => 
                        <div 
                            className={ cn(classes.wrapper, {
                                [classes.visible]: isVisible
                            }) }
                        >
                            <div className={ classes.services }>
                                <div className={ classes.service }>
                                    <div className={ classes.iconContainer }>
                                        <img src={ individualIcon } className={ classes.icon } alt=""/>
                                    </div>
                                    <div className={ classes.title }>
                                        { this.props.lang === 'ukr' ? 'Індивідуальні заняття' : 'Индивидуальные уроки' }
                                    </div>
                                    <div className={ classes.badges }>
                                        { onlineBadge }
                                        { offlineBadge }
                                    </div>
                                </div>
                                <div className={ classes.service }>
                                    <div className={ classes.iconContainer }>
                                        <img src={ groupIcon } className={ classes.icon } alt=""/>
                                    </div>
                                    <div className={ classes.title }>
                                        { this.props.lang === 'ukr' ? 'Групові заняття' : 'Групповые уроки' }
                                    </div>
                                    <div className={ classes.badges }>
                                        { onlineBadge }
                                        { offlineBadge }
                                    </div>
                                </div>
                                <div className={ classes.service }>
                                    <div className={ classes.iconContainer }>
                                        <img src={ corporateIcon } className={ classes.icon } alt=""/>
                                    </div>
                                    <div className={ classes.title }>
                                        { this.props.lang === 'ukr' ? 'Корпоративні заняття' : 'Корпоративные уроки' }
                                    </div>
                                    <div className={ classes.badges }>
                                        { offlineBadge }
                                    </div>
                                </div>
                                <div className={ classes.service }>
                                    <div className={ classes.iconContainer }>
                                        <img src={ clockIcon } className={ classes.icon } alt=""/>
                                    </div>
                                    <div className={ classes.title }>
                                        { this.props.lang === 'ukr' ? `'Хвилинки'` : `'Минутки'` }
                                    </div>
                                    <div className={ classes.badges }>
                                        { onlineBadge }
                                    </div>
                                </div>
                                <div className={ classes.service }>
                                    <div className={ classes.iconContainer }>
                                        <img src={ speakingClubIcon } className={ classes.icon } alt=""/>
                                    </div>
                                    <div className={ classes.title }>Speaking club</div>
                                    <div className={ classes.badges }>
                                        { offlineBadge }
                                    </div>
                                </div>
                                <div className={ classes.service }>
                                    <div className={ classes.iconContainer }>
                                        <img src={ workshopsIcon } className={ classes.icon } alt=""/>
                                    </div>
                                    <div className={ classes.title }>
                                        { this.props.lang === 'ukr' ? 'Воркшопи' : 'Воркшопы' }
                                    </div>
                                    <div className={ classes.badges }>
                                        { onlineBadge }
                                        { offlineBadge }
                                    </div>
                                </div>
                
                            </div>
                            <div className={ classes.buttonContainer }>
                                <Button moreButton clicked={ this.props.handleNavigateToServices } />
                            </div>
                        </div>
                }
            </VisibilitySensor>
        )
    }
}

export default WhatWeCan;