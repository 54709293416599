import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Calendar.module.scss';
import axios from 'axios';
import ReactGA from 'react-ga';
import EventLink from './EventLink/EventLink';
import Title from '../Title/Title';
import AgendaItem from './Agenda/AgendaItem';
import Footer from '../Footer/Footer';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

class Calendar extends Component {

    state = {
        pageContent: {},
        events: [],
        agenda: [],
        isAgendaInvisible: true,
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        
        axios.get('https://api.engmonsters.in.ua/calendar_page/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            this.setState({
                pageContent: response.data.texts,
                events: response.data.events,
                agenda: response.data.agenda.reverse(),
                seo: response.data.seo
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.lang !== prevProps.lang) {
            axios.get('https://api.engmonsters.in.ua/calendar_page/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    pageContent: response.data.texts,
                    events: response.data.events,
                    agenda: response.data.agenda.reverse(),
                    seo: response.data.seo
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    makeAgendaVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isAgendaInvisible: false
            })
        }
    }

    render() {

        const events = this.state.events.map((event, index) => (
            <EventLink
                index={ index }
                key={ event.url }
                url={ event.url }
                photo={ event.pic }
                name={ event.name }
                time={ event.time }
                price={ event.price }
                place={ event.place }
                description={ event.short_description }
                fbUrl={ event.fb_url }
            />
        ))

        const agendaItems = this.state.agenda.map((item, index) => (
            <AgendaItem
                key={ index }
                index={ index }
                title={ item.name }
                agenda={ item.agenda }
                people={ item.people }
                date={ item.date }
                url={ item.url }
            />
        ))

        return (
            <div className={ classes.calendar }>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <div className={ classes.wrapper }>
                    { events.length > 0 && (
                        <>
                            <Title
                                center={ window.innerWidth >= 1100 ? false : true }
                            >
                                { this.state.pageContent.events_header }
                            </Title>
                            <div className={ classes.events }>
                                { events }
                            </div>
                        </>
                    )}
                    <Title
                        center={ window.innerWidth >= 1100 ? false : true }
                    >
                        { this.state.pageContent.agenda_header }
                    </Title>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isInvisible } onChange={ this.makeTitleVisible } >
                        { ({ isVisible }) => 
                            <div className={ cn(classes.agenda, {
                                [classes.visible]: isVisible
                            }) }>
                                { agendaItems }
                            </div>
                        }
                    </VisibilitySensor>
                </div>
                <Footer lang={ this.props.lang } />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

export default connect(mapStateToProps)(Calendar);