import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classes from './Corporate.module.scss';
import VisibilitySensor from 'react-visibility-sensor';
import cn from 'classnames'

// assets

import corporatePortrait from '../../../assets/img/services-company-portrait-corporate.png'
import individualPortrait from '../../../assets/img/services-company-portrait-individual.png'

import dollarYellowIcon from '../../../assets/img/dollar-yellow.svg'
import dollarGreenIcon from '../../../assets/img/dollar-green.svg'
import clockYellowIcon from '../../../assets/img/clock-yellow.svg'
import clockGreenIcon from '../../../assets/img/clock-green.svg'
import pinYellowIcon from '../../../assets/img/pin-yellow.svg'
import pinGreenIcon from '../../../assets/img/pin-green.svg'
import personGreenIcon from '../../../assets/img/person-green.svg'
import personYellowIcon from '../../../assets/img/person-yellow.svg'

import companiesBackground from '../../../assets/img/services-companies-bg.png';

class Corporate extends Component {

    state = {
        isIndividualVisible: true,
        isCorporateVisible: true,
        isCompaniesVisible: true
    }

    makeIndividualVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isIndividualVisible: false
            })
        }
    }

    makeCorporateVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isCorporateVisible: false
            })
        }
    }

    makeCompaniesVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isCompaniesVisible: false
            })
        }
    }

    render() {

        const companies = this.props.companies.map((company, index) => (
            <a href={ company.url } key={ index } className={ classes.companyItem }>
                <img src={ company.pic } alt="" />
            </a>
        ))
    
        return (
            <>
                <div className={ classes.wrapper }>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isCorporateVisible } onChange={ this.makeCorporateVisible } >
                                {
                                    ({ isVisible }) => 
                                    <div className={ cn(classes.corporate, {
                                        [classes.visible]: isVisible
                                    }) }>
                                        <div className={ classes.info }>
                                            { this.props.content && this.props.content[1].url ? (
                                                <Link to={`/service/corporate/${ this.props.content[1].url }`} className={ classes.title }>
                                                    { this.props.content && this.props.content[1].name }
                                                </Link>
                                            ) : (
                                                <div className={ classes.title }>
                                                    { this.props.content && this.props.content[1].name }
                                                </div>
                                            ) }
                                            <div className={ classes.text } dangerouslySetInnerHTML={ { __html:  this.props.content && this.props.content[1].desc } } />
                                            <div className={ classes.details }>
                                                { this.props.content && this.props.content[1].offline_price && (
                                                    <div className={ classes.price }>
                                                        <img src={ dollarGreenIcon } alt="" />
                                                        { this.props.content[1].offline_price }
                                                    </div>
                                                ) }
                                                { this.props.content && this.props.content[1].offline_time && (
                                                    <div className={ classes.time }>
                                                        <img src={ clockGreenIcon } alt="" />
                                                        { this.props.content[1].offline_time }
                                                    </div>
                                                ) }
                                                { this.props.content && this.props.content[1].offline_place && (
                                                    <div className={ classes.place }>
                                                        <img src={ pinGreenIcon } alt="" />
                                                        { this.props.content[1].offline_place }
                                                    </div>
                                                ) }
                                                { this.props.content && this.props.content[1].offline_people && (
                                                    <div className={ classes.time }>
                                                        <img src={ personGreenIcon } alt="" />
                                                        { this.props.content[1].offline_people }
                                                    </div>
                                                ) }
                                            </div>
                                        </div>
                                        <div className={ classes.image }>
                                            <img src={ corporatePortrait } alt="" />
                                        </div>
                                    </div>
                                }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isIndividualVisible } onChange={ this.makeIndividualVisible } >
                            {
                                ({ isVisible }) => 
                                <div className={ cn(classes.individual, {
                                    [classes.visible]: isVisible
                                }) }>
                                    <div className={ classes.image }>
                                        <img src={ individualPortrait } alt="" />
                                    </div>
                                    <div className={ classes.info }>
                                        { this.props.content && this.props.content[2].url ? (
                                            <Link to={`/service/corporate/${ this.props.content[2].url }`} className={ classes.title }>
                                                { this.props.content && this.props.content[2].name }
                                            </Link>
                                        ) : (
                                            <div className={ classes.title }>
                                                { this.props.content && this.props.content[2].name }
                                            </div>
                                        ) }
                                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html:  this.props.content && this.props.content[2].desc } } />
                                        <div className={ classes.details }>
                                            { this.props.content && this.props.content[1].offline_price && (
                                                <div className={ classes.price }>
                                                    <img src={ dollarYellowIcon } alt="" />
                                                    { this.props.content[1].offline_price }
                                                </div>
                                            ) }
                                            { this.props.content && this.props.content[1].offline_time && (
                                                <div className={ classes.time }>
                                                    <img src={ clockYellowIcon } alt="" />
                                                    { this.props.content[1].offline_time }
                                                </div>
                                            ) }
                                            { this.props.content && this.props.content[1].offline_place && (
                                                <div className={ classes.place }>
                                                    <img src={ pinYellowIcon } alt="" />
                                                    { this.props.content[1].offline_place }
                                                </div>
                                            ) }
                                            { this.props.content && this.props.content[1].offline_people && (
                                                <div className={ classes.time }>
                                                    <img src={ personYellowIcon } alt="" />
                                                    { this.props.content[1].offline_people }
                                                </div>
                                            ) }
                                        </div>
                                    </div>
                                </div>
                            }
                    </VisibilitySensor>
                </div>
                <div className={ classes.companies }>
                    <img src={ companiesBackground } alt="" className={ classes.background } />
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isCompaniesVisible } onChange={ this.makeCompaniesVisible } >
                            {
                                ({ isVisible }) => 
                                <div className={ cn(classes.wrapper, {
                                    [classes.visible]: isVisible
                                }) }
                                >
                                    <div className={ classes.title }>
                                        { this.props.companiesTitle }
                                    </div>
                                    <div className={ classes.companiesList }>
                                        { companies }
                                    </div>
                                </div>
                            }   
                    </VisibilitySensor>
                </div>
            </>
        )
    }
}

export default Corporate;