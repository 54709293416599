import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import * as actions from '../../../store/actions';
import classes from './Vacancy.module.scss';
import axios from 'axios';
import CallToAction from '../../CallToAction/CallToAction';
import ReactGA from 'react-ga';
import Button from '../../Button/Button';

import bottle from '../../../assets/img/hiring-bottle.svg'
// import glass from '../../../assets/img/hiring-glass.svg'
// import martiniGlass from '../../../assets/img/hiring-martini-glass.svg'

const svgPath = (
    <svg width="0" height="0" viewBox="0 0 770 521" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="vacancyClipPath">
            <path d="M67.6318 494C129.277 522.125 315.671 520 406.446 520C497.22 520 654.276 525.949 708.47 492.667C762.664 459.386 770.979 363.834 768.946 254.749C766.914 145.663 757.244 69.617 708.47 38.2105C659.696 6.80396 451.427 0 316.62 0C181.814 0 129.277 8.05408 67.6316 40.8668C5.98658 73.6795 5.14794 221.953 1.08342 316.039C-2.98109 410.124 5.98665 465.875 67.6318 494Z" fill="#FDCF41"/>
        </clipPath>
    </svg>
)

const svgPathMobile = (
    <svg className={ classes.clipSvg } width="296" height="200" viewBox="0 0 296 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="vacancyClipPath">
            <path d="M25.897 189.849C49.5879 200.658 121.221 199.841 156.107 199.841C190.992 199.841 251.351 202.128 272.178 189.337C293.005 176.547 296.201 139.825 295.42 97.9026C294.639 55.9799 290.922 26.7545 272.178 14.6847C253.433 2.61482 173.394 0 121.586 0C69.7783 0 49.5878 3.09526 25.897 15.7055C2.20613 28.3158 1.88383 85.299 0.321793 121.457C-1.24024 157.615 2.20616 179.041 25.897 189.849Z" />
        </clipPath>
    </svg>
)

class Vacancy extends Component {

    state = {
        vacancy: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        let lastURLSegment = this.props.match.params.vacancyUrl

        axios.get('https://api.engmonsters.in.ua/job/', {
            params: {
                lang: this.props.lang,
                url: lastURLSegment
            }
        })
        .then(response => {
            this.setState({
                vacancy: response.data[0]
            })
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.vacancyUrl !== prevProps.match.params.vacancyUrl || this.props.lang !== prevProps.lang) {
            if(this.props.match.params.vacancyUrl !== prevProps.match.params.vacancyUrl) {
                window.scrollTo(0, 0) 
            }
            let lastURLSegment = this.props.match.params.vacancyUrl

            axios.get('https://api.engmonsters.in.ua/job/', {
                params: {
                    lang: this.props.lang,
                    url: lastURLSegment
                }
            })
            .then(response => {
                this.setState({
                    vacancy: response.data[0]
                })
            })
        }
    }

    showModal = () => {
        this.props.showModal()
    }

    render() {

        const clipSvg = window.innerWidth < 800 ? svgPathMobile : svgPath;

        return (
            <>
                <div className={ classes.vacancy }>
                    <Helmet>
                        <title>{ this.state.vacancy.name }</title>
                    </Helmet>
                    <div className={ classes.wrapper }>
                        <div className={ classes.photo }>
                            { clipSvg }
                            <img src={ bottle } alt=""  />
                        </div>
                        <div className={ classes.title }>
                            { this.state.vacancy.name }
                        </div>
                        <div className={ classes.dateMobile }>
                            { this.state.vacancy.date }
                        </div>
                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.vacancy.content } }/>
                        <div className={ classes.authorContainer }>
                            <div className={ classes.date }>
                                { this.state.vacancy.date }
                            </div>
                            <div className={ classes.socialButtons }>
                                <Button
                                    facebook
                                    inFooter
                                    right
                                />
                                <Button
                                    telegram
                                    inFooter
                                    right
                                />
                            </div>
                            <div className={ classes.social }>
                                { this.props.lang === 'ukr' ? 'Поширити:' : 'Поделиться'}
                            </div>
                        </div>
                    </div>
                    <div className={ classes.shareMobile }>
                        <div className={ classes.socialMobile }>
                            { this.props.lang === 'ukr' ? 'Поширити:' : 'Поделиться'}
                        </div>
                        <div className={ classes.socialButtonsMobile }>
                                <Button
                                    facebook
                                />
                                <Button
                                    telegram
                                />
                        </div>
                    </div>
                </div>
                <CallToAction 
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vacancy);