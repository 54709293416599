import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Event.module.scss';
import axios from 'axios';
import ReactGA from 'react-ga';
import cn from 'classnames';

import dollarGreenIcon from '../../../assets/img/dollar-green.svg';
import clockGreenIcon from '../../../assets/img/clock-green.svg';
import pinGreenIcon from '../../../assets/img/pin-green.svg';
import calendarGreenIcon from '../../../assets/img/calendar-icon-green.svg'
import Button from '../../Button/Button';
import Footer from '../../Footer/Footer';

const svgPath = (
    <svg className={ classes.clipSvg } width="449" height="418" viewBox="0 0 449 418" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="eventPhotoPath">
            <path d="M28.3898 395.109C64.243 413.992 185.214 417.441 238.009 417.441C290.804 417.441 382.149 414.721 413.668 392.376C445.188 370.032 450.024 244.273 448.842 171.034C447.66 97.796 442.036 46.7398 413.668 25.654C385.301 4.56808 264.17 3.05176e-05 185.766 3.05176e-05C107.362 3.05176e-05 76.8058 12.1212 40.9527 34.1512C5.09951 56.1812 4.61175 149.016 2.2478 212.184C-0.116146 275.351 -7.46344 376.226 28.3898 395.109Z"/>
        </clipPath>
    </svg>
)

const svgPathMobile = (
    <svg width="280" height="260" viewBox="0 0 280 260" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="eventPhotoPathMobile">
            <path d="M17.6824 246.091C40.0133 257.852 115.359 260 148.242 260C181.125 260 238.019 258.306 257.65 244.389C277.282 230.472 280.294 152.143 279.558 106.527C278.822 60.9115 275.319 29.1115 257.65 15.9783C239.982 2.84515 164.537 0 115.703 0C66.8694 0 47.8379 7.54961 25.5071 21.2708C3.17619 34.992 2.87239 92.8135 1.40003 132.157C-0.0723408 171.5 -4.64855 234.33 17.6824 246.091Z" />
        </clipPath>
    </svg>
)

class Event extends Component {

    state = {
        event: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        
        axios.get('https://api.engmonsters.in.ua/event/', {
            params: {
                lang: this.props.lang,
                url: this.props.match.params.eventUrl
            }
        })
        .then(response => {
            this.setState({
                event: response.data[0]
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.eventUrl !== prevProps.match.params.eventUrl || this.props.lang !== prevProps.lang) {
            if(this.props.match.params.eventUrl !== prevProps.match.params.eventUrl) {
                window.scrollTo(0, 0);
            }

            axios.get('https://api.engmonsters.in.ua/event/', {
                params: {
                    lang: this.props.lang,
                    url: this.props.match.params.eventUrl
                }
            })
            .then(response => {
                this.setState({
                    event: response.data[0]
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    render() {

        const imageClip = window.innerWidth <= 600 ? svgPathMobile : svgPath

        return(
            <>
                <div className={ classes.event }>
                    <Helmet>
                        <title>{ `${ this.state.event.name } | English Monsters` }</title>
                    </Helmet>
                    <div className={ classes.wrapper }>
                        <div className={ classes.photo }>
                            { imageClip }
                            <img src={ this.state.event.pic } alt="" />
                        </div>
                        <div className={ classes.title }>{ this.state.event.name }</div>
                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.event.content } } />
                        <div className={ classes.optionsContainer }>
                            { this.state.event.date && (
                                <div className={ classes.option }>
                                    <img src={ calendarGreenIcon } alt="" className={ classes.icon } />
                                    <span>
                                        { this.state.event.date }
                                    </span>
                                </div>
                            ) }
                            { this.state.event.time && (
                                <div className={ classes.option }>
                                    <img src={ clockGreenIcon } alt="" className={ classes.icon } />
                                    <span>
                                        { this.state.event.time }
                                    </span>
                                </div>
                            ) }
                            { this.state.event.place && (
                                <div className={ classes.option }>
                                    <img src={ pinGreenIcon } alt="" className={ cn(classes.icon, classes.pin) } />
                                    <span>
                                        { this.state.event.place }
                                    </span>
                                </div>
                            ) }
                            { this.state.event.price && (
                                <div className={ classes.option }>
                                    <img src={ dollarGreenIcon } alt="" className={ cn(classes.icon, classes.price) } />
                                    <span>
                                        { this.state.event.price }
                                    </span>
                                </div>
                            ) }
                        </div>
                        <div className={ classes.buttons }>
                            { this.state.event.google_date && (
                                <Button 
                                    addToCalendar
                                    href={ `https://www.google.com/calendar/render?action=TEMPLATE&text=${ this.state.event.name }&dates=${ this.state.event.google_date }&sf=true&output=xml` }
                                />
                            ) }
                            { this.state.event.fb_url && <Button regForEventButton fbUrl={ this.state.event.fb_url } />}
                        </div>
                    </div>
                </div>
                <Footer lang={ this.props.lang } />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

export default connect(mapStateToProps)(Event);