import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button/Button';
import TypeSwitch from '../TypeSwitch/TypeSwitch';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import classes from './MobileMenu.module.scss';
import cn from 'classnames';

const MobileMenu = (props) => (
    <div className={ cn(classes.menu, {
        [classes.opened]: props.isMenuOpened
    }) }>
        {
            props.isMenuOpened &&
            <>
                <div className={ classes.links }>
                    <Link 
                        onClick={ props.closeMenuButtonClicked } 
                        className={ classes.menuItem } 
                        to="/methods"
                    >
                        Методика
                    </Link>
                    <Link 
                        onClick={ props.closeMenuButtonClicked } 
                        className={ classes.menuItem } 
                        to="/services"
                    >
                        { props.lang === 'ukr' ? 'Послуги' : 'Услуги' }
                    </Link>
                    <Link 
                        className={ classes.menuItem }
                        to="/monsters"
                        onClick={ props.closeMenuButtonClicked }
                    >
                        { props.lang === 'ukr' ? 'Монстри' : 'Монстры' }
                    </Link>
                    <Link 
                        className={ classes.menuItem } 
                        to="/calendar"
                        onClick={ props.closeMenuButtonClicked }
                    >
                        { props.lang === 'ukr' ? 'Календар' : 'Календарь' }
                    </Link>
                    <Link
                        className={ classes.menuItem }
                        to="/blog/all"
                        onClick={ props.closeMenuButtonClicked }
                    >
                        Блог
                    </Link>
                </div>
                <div className={ classes.buttonContainer }>
                    <Button callButton clicked={ props.showModal } />
                </div>
                <div className={ classes.switchesOuter }>
                    <div className={ classes.switches }>
                        <LanguageSwitch />
                        <TypeSwitch inMenu />
                    </div>
                </div>
            </>
        }
    </div>
)

export default MobileMenu;