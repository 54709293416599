import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions'
import Layout from './components/Layout/Layout';

// pages

import Home from './components/Home/Home';
import Services from './components/Services/Services';
import Blog from './components/Blog/Blog';
import Post from './components/Blog/Post/Post';
import PostPreview from './components/Blog/Post/PostPreview';
import Shop from './components/Shop/Shop';
import Methodology from './components/Methodology/Methodology';
import Faq from './components/Faq/Faq';
import Monsters from './components/Monsters/Monsters';
import Calendar from './components/Calendar/Calendar';
import Event from './components/Calendar/Event/Event';
import Hiring from './components/Hiring/Hiring';
import Vacancy from './components/Hiring/Vacancy/Vacancy';
import PageNotFound from './components/PageNotFound/PageNotFound';
import Group from './components/Calendar/Group/Group';
import Service from './components/Services/Service/Service';
import CustomPage from './components/CustomPage/CustomPage';

class App extends Component {

  switchLang = (lang) => {
    this.props.switchLang(lang)
  }

  switchType = (type) => {
    this.props.switchType(type)
  }

  showModal = () => {
    this.props.showModal()
  }

  hideModal = () => {
    this.props.hideModal()
  }

  render(){
    let routes = (
        <Switch>
          <Route path="/" exact component={ Home } />
          <Route path="/services" exact component={ Services } />
          <Route path="/blog/:category"  component={ Blog } />
          <Route path="/post/:post"  component={ Post } />
          <Route path="/post-preview/:post"  component={ PostPreview } />
          <Route path="/shop" exact component={ Shop } />
          <Route path="/methods" exact component={ Methodology } />
          <Route path="/faq" exact component={ Faq } />
          <Route path="/monsters" exact component={ Monsters } />
          <Route path="/calendar" exact component={ Calendar } />
          <Route path="/hiring" exact component={ Hiring } />
          <Route path="/vacancy/:vacancyUrl" exact component={ Vacancy } />
          <Route path="/event/:eventUrl" exact component={ Event } />
          <Route path="/group/:groupUrl" exact component={ Group } />
          <Route path="/service/:serviceType/:serviceUrl" exact component={ Service } />
          <Route path="/404" exact component={ PageNotFound } />
          <Route path="/:cutomPageId" component={ CustomPage } />
        </Switch>
    )    

    return (
      <Layout
        lang={ this.props.lang }
        isModalVisible={ this.props.isModalVisible }
        hideModal={ this.hideModal }
        showModal={ this.showModal }
        modalType={ this.props.modalType }
      >
        {routes}
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
      lang: state.basic.lang,
      isModalVisible: state.basic.isModalVisible,
      modalType: state.basic.modalType
  }
}

const mapDispatchToProps = dispatch => {
  return{
      showModal: () => dispatch(actions.showModal()),
      hideModal: () => dispatch(actions.hideModal())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
