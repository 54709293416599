import React, { Component } from 'react';
import classes from './Button.module.scss';
import cn from 'classnames';
import messangerIcon from '../../assets/img/button-messanger-icon.svg';
import whiteMessangerIcon from '../../assets/img/white-button-messanger-icon.svg';
import facebook from '../../assets/img/facebook.svg';
import youtube from '../../assets/img/youtube.svg';
import instagram from '../../assets/img/instagram.svg';
import telegram from '../../assets/img/telegram.svg';
import { connect } from 'react-redux';
// import fbButtonIcon from '../../assets/img/fbButtonIcon.svg';

class Button extends Component {
    render () {
        if(this.props.callButton) {
            return(
                <div 
                    className={ cn(classes.button, classes.call, {
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left,
                        [classes.headerBtn]: this.props.inHeader,
                        [classes.center]: this.props.center
                    }) }
                    onClick={ this.props.clicked }
                >
                    <svg width="256" height="65" viewBox="0 0 256 65" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M256 36.3549C256 65.7381 210.864 64.539 126.256 63.6923C41.6481 62.8455 0 69.2242 0 31.0938C0 -0.996559 37.6676 0.00344189 147.572 0.00344189C245.953 0.00344189 256 6.97176 256 36.3549Z"/>
                    </svg>
                    <svg width="256" height="65" viewBox="0 0 256 65" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M256 36.3549C256 65.7381 210.864 64.539 126.256 63.6923C41.6481 62.8455 0 69.2242 0 31.0938C0 -0.996559 37.6676 0.00344189 147.572 0.00344189C245.953 0.00344189 256 6.97176 256 36.3549Z"/>
                    </svg>
                    <div className={ classes.text }>
                        { this.props.lang === 'ukr' ? 'Замовити дзвінок' : 'Заказать звонок' }
                    </div>
                </div>
            )
        } else if(this.props.buyButton) {
            return(
                <div 
                    className={ cn(classes.button, classes.call, {
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left,
                        [classes.headerBtn]: this.props.inHeader
                    }) }
                    onClick={ this.props.clicked }
                >
                    <svg width="256" height="65" viewBox="0 0 256 65" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M256 36.3549C256 65.7381 210.864 64.539 126.256 63.6923C41.6481 62.8455 0 69.2242 0 31.0938C0 -0.996559 37.6676 0.00344189 147.572 0.00344189C245.953 0.00344189 256 6.97176 256 36.3549Z"/>
                    </svg>
                    <svg width="256" height="65" viewBox="0 0 256 65" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M256 36.3549C256 65.7381 210.864 64.539 126.256 63.6923C41.6481 62.8455 0 69.2242 0 31.0938C0 -0.996559 37.6676 0.00344189 147.572 0.00344189C245.953 0.00344189 256 6.97176 256 36.3549Z"/>
                    </svg>
                    <div className={ classes.text }>
                        { this.props.lang === 'ukr' ? 'Купити' : 'Купить' }
                    </div>
                </div>
            )
        } else if(this.props.callMyBoss) {
            return(
                <div 
                    className={ cn(classes.button, classes.callMyBoss, {
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                    onClick={ this.props.clicked }
                >
                    <svg width="365" height="64" viewBox="0 0 365 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M365 36.353C365 65.7345 300.646 64.5355 180.014 63.6888C59.3811 62.8422 0 69.2205 0 31.0922C0 -0.996506 53.7057 0.00344171 210.406 0.00344171C350.676 0.00344171 365 6.97138 365 36.353Z"/>
                    </svg>
                    <svg width="365" height="64" viewBox="0 0 365 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M365 36.353C365 65.7345 300.646 64.5355 180.014 63.6888C59.3811 62.8422 0 69.2205 0 31.0922C0 -0.996506 53.7057 0.00344171 210.406 0.00344171C350.676 0.00344171 365 6.97138 365 36.353Z"/>
                    </svg>
                    <div className={ classes.text }>
                        { this.props.text }
                    </div>
                </div>
            )
        } else if(this.props.addToCalendar) {
            return(
                <a 
                    href={ this.props.href }
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.addToCalendar) }
                >
                    <svg width="306" height="64" viewBox="0 0 306 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M306 36.353C306 65.7345 252.049 64.5355 150.916 63.6888C49.7825 62.8422 0 69.2205 0 31.0922C0 -0.996506 45.0245 0.00344171 176.395 0.00344171C293.991 0.00344171 306 6.97138 306 36.353Z" />
                    </svg>
                    <svg width="306" height="64" viewBox="0 0 306 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M306 36.353C306 65.7345 252.049 64.5355 150.916 63.6888C49.7825 62.8422 0 69.2205 0 31.0922C0 -0.996506 45.0245 0.00344171 176.395 0.00344171C293.991 0.00344171 306 6.97138 306 36.353Z" />
                    </svg>
                    <div className={ classes.text }>
                        { this.props.lang === 'ukr' ? 'Додати в календар' : 'Добавить в календарь' }
                    </div>
                </a>
            )
        } else if(this.props.messangerButton) {
            return (
                <a 
                    href="https://m.me/engmonsters" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.messanger, {
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                    onClick={ this.props.clicked }
                >
                    <svg width="277" height="64" viewBox="0 0 277 64" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M277 36.353C277 65.7345 228.162 64.5355 136.613 63.6888C45.0646 62.8422 0 69.2205 0 31.0922C0 -0.996506 40.7575 0.00344171 159.678 0.00344171C266.129 0.00344171 277 6.97138 277 36.353Z"/>
                    </svg>
                    <svg width="277" height="64" viewBox="0 0 277 64" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M277 36.353C277 65.7345 228.162 64.5355 136.613 63.6888C45.0646 62.8422 0 69.2205 0 31.0922C0 -0.996506 40.7575 0.00344171 159.678 0.00344171C266.129 0.00344171 277 6.97138 277 36.353Z"/>
                    </svg>
                    <div className={ classes.text }>
                        <img src={ messangerIcon } alt="" />
                        { this.props.lang === 'ukr' ? 'Написати нам' : 'Написать нам' }
                    </div>
                </a>
            )
        } else if(this.props.regForEventButton) {
            return (
                <a 
                    href={ this.props.fbUrl } 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.regForEventButton) }
                >
                    <svg width="306" height="64" viewBox="0 0 306 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M306 36.2822C306 63.8747 232.909 64.4937 145.636 63.8747C58.3636 63.2557 0 64.8693 0 32.4347C0 9.51858e-05 48.7283 0 168.624 0C275.949 0 306 3.69931 306 36.2822Z" />
                    </svg>
                    <svg width="306" height="64" viewBox="0 0 306 64" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M306 36.2822C306 63.8747 232.909 64.4937 145.636 63.8747C58.3636 63.2557 0 64.8693 0 32.4347C0 9.51858e-05 48.7283 0 168.624 0C275.949 0 306 3.69931 306 36.2822Z" />
                    </svg>
                    <div className={ classes.text }>
                        {/* <img src={ fbButtonIcon } alt="" /> */}
                        { this.props.lang === 'ukr' ? 'Зареєструватися' : 'Зарегистрироваться' }
                    </div>
                </a>
            )
        } else if(this.props.whiteMessangerButton) {
            return (
                <a 
                    href="https://m.me/engmonsters" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.whiteMessanger, {
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                    onClick={ this.props.clicked }
                >
                    <svg width="277" height="64" viewBox="0 0 277 64" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M277 36.353C277 65.7345 228.162 64.5355 136.613 63.6888C45.0646 62.8422 0 69.2205 0 31.0922C0 -0.996506 40.7575 0.00344171 159.678 0.00344171C266.129 0.00344171 277 6.97138 277 36.353Z"/>
                    </svg>
                    <svg width="277" height="64" viewBox="0 0 277 64" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M277 36.353C277 65.7345 228.162 64.5355 136.613 63.6888C45.0646 62.8422 0 69.2205 0 31.0922C0 -0.996506 40.7575 0.00344171 159.678 0.00344171C266.129 0.00344171 277 6.97138 277 36.353Z"/>
                    </svg>
                    <div className={ classes.text }>
                        <img src={ whiteMessangerIcon } alt="" />
                        { this.props.lang === 'ukr' ? 'Написати нам' : 'Написать нам' }
                    </div>
                </a>
            )
        } else if(this.props.moreButton) {
            return (
                <div 
                    className={ cn(classes.button, classes.more, {
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                    onClick={ this.props.clicked }
                >
                    <svg width="281" height="65" viewBox="0 0 281 65"  xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M280.5 36.3515C280.5 63.9966 213.5 64.6167 133.5 63.9966C53.5 63.3764 0 64.9931 0 32.4966C0 9.53674e-05 44.6676 0 154.572 0C252.953 0 280.5 3.70637 280.5 36.3515Z"/>
                    </svg>
                    <svg width="281" height="66" viewBox="0 0 281 66" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M280.5 37.3481C280.5 64.9932 213.5 65.6133 133.5 64.9932C53.5 64.373 0 65.9897 0 33.4932C0 0.996677 44.6676 0.996582 154.572 0.996582C252.953 0.996582 280.5 0.996582 280.5 37.3481Z"/>
                    </svg>
                    <div className={ classes.text }>
                        { this.props.lang === 'ukr' ? 'Дізнатись більше' : 'Узнать больше' }
                    </div>
                </div>
            )
        } else if(this.props.facebook) {
            return (
                <a
                    href={ this.props.shareButton ? `http://www.facebook.com/sharer.php?u=${ window.location.href }` : "https://www.facebook.com/engmonsters" } 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.facebook, {
                        [classes.inFooter]: this.props.inFooter,
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                >
                    <svg width="0" height="0" viewBox="0 0 0 0" xmlns="http://www.w3.org/2000/svg">
                        <clipPath id="facebookClipPath">
                            <path d="M118 71.4C118 104.261 92.063 119 59.3081 119C14.9426 119 0 92.3609 0 59.5C0 19.3182 26.5531 0 59.3081 0C107.217 0 118 38.5391 118 71.4Z"/>
                        </clipPath>
                    </svg>
                    <svg width="118" height="119" viewBox="0 0 118 119" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M118 71.4C118 104.261 92.063 119 59.3081 119C14.9426 119 0 92.3609 0 59.5C0 19.3182 26.5531 0 59.3081 0C107.217 0 118 38.5391 118 71.4Z"/>
                    </svg>
                    <svg width="118" height="119" viewBox="0 0 118 119" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M118 71.4C118 104.261 92.063 119 59.3081 119C14.9426 119 0 92.3609 0 59.5C0 19.3182 26.5531 0 59.3081 0C107.217 0 118 38.5391 118 71.4Z"/>
                    </svg>
                    <div className={ classes.iconContainer }>
                        <img className={ classes.icon } alt="" src={ facebook } />
                    </div>
                </a>
            )
        } else if(this.props.youtube) {
            return (
                <a
                    href="https://www.youtube.com/channel/UCwPLG8KYxaAT3nJoFYmZPsg" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.youtube, {
                        [classes.inFooter]: this.props.inFooter,
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                >
                    <svg width="120" height="119" viewBox="0 0 120 119" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M120 70.5003C120 103.592 100.208 120.458 60 118.901C19.7922 117.345 0 92.0752 0 58.9837C0 25.8922 14.1818 0 70.1299 0C116.883 0 120 37.4088 120 70.5003Z"/>
                    </svg>
                    <svg width="120" height="119" viewBox="0 0 120 119" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M120 70.5003C120 103.592 100.208 120.458 60 118.901C19.7922 117.345 0 92.0752 0 58.9837C0 25.8922 14.1818 0 70.1299 0C116.883 0 120 37.4088 120 70.5003Z"/>
                    </svg>
                    <img className={ classes.icon } alt="" src={ youtube } />
                </a>
            )
        } else if(this.props.instagram) {
            return (
                <a 
                    href="https://www.instagram.com/engmonsters/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.instagram, {
                        [classes.inFooter]: this.props.inFooter,
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                >
                    <div className={ classes.hoverOverlay }></div>
                    <svg width="118" height="116" viewBox="0 0 118 116" fill="none" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M118 49.9286C118 94.1769 101.703 116 60.2072 116C28.1224 116 0 91.8635 0 59.8618C0 22.0852 7.69565 -1.84501 60.2072 0.11157C111.21 0.11157 118 17.9269 118 49.9286Z" fill="url(#paint0_linear)"/>
                        <defs>
                        <linearGradient id="paint0_linear" x1="118" y1="7.5" x2="18.5" y2="109" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FA8F21"/>
                            <stop offset="0.536458" stopColor="#E24964"/>
                            <stop offset="1" stopColor="#AA3A8A" stopOpacity="0.78"/>
                        </linearGradient>
                        </defs>
                    </svg>
                    <svg width="118" height="116" viewBox="0 0 118 116" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M118 49.9286C118 94.1769 101.703 116 60.2072 116C28.1224 116 0 91.8635 0 59.8618C0 22.0852 7.69565 -1.84501 60.2072 0.11157C111.21 0.11157 118 17.9269 118 49.9286Z"/>
                    </svg>
                    <img className={ classes.icon } alt="" src={ instagram } />
                </a>
            )
        } else if(this.props.telegram) {
            return (
                <a 
                    href={ this.props.shareButton ? `https://telegram.me/share/url?url=${ window.location.href }` : "https://t.me/engmonsters" }
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={ cn(classes.button, classes.telegram, {
                        [classes.inFooter]: this.props.inFooter,
                        [classes.right]: this.props.right,
                        [classes.left]: this.props.left
                    }) }
                >
                    <svg width="119" height="116" viewBox="0 0 119 116" xmlns="http://www.w3.org/2000/svg" className={ classes.topSvg }>
                        <path d="M119 49.9286C119 94.1769 102.565 116 60.7174 116C28.3607 116 0 91.8635 0 59.8618C0 22.0852 7.76087 -1.84501 60.7174 0.11157C112.152 0.11157 119 17.9269 119 49.9286Z"/>
                    </svg>
                    <svg width="119" height="116" viewBox="0 0 119 116" xmlns="http://www.w3.org/2000/svg" className={ classes.bottomSvg }>
                        <path d="M119 49.9286C119 94.1769 102.565 116 60.7174 116C28.3607 116 0 91.8635 0 59.8618C0 22.0852 7.76087 -1.84501 60.7174 0.11157C112.152 0.11157 119 17.9269 119 49.9286Z"/>
                    </svg>
                    <img className={ classes.icon } alt="" src={ telegram } />
                </a>
            )
        } 
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

export default connect(mapStateToProps)(Button);