import React, { Component } from 'react';
import classes from './Title.module.scss';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

class Title extends Component {
    state = {
        isInvisible: true,
    }

    makeTitleVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isInvisible: false
            })
        }
    }

    render() {
        return (
            <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isInvisible } onChange={ this.makeTitleVisible } >
                { ({ isVisible }) => 
                    <h3
                        className={ cn(classes.title, {
                            [classes.center]: this.props.center,
                            [classes.callToAction]: this.props.callToAction,
                            [classes.visible]: isVisible
                        }) } 
                        style={ { color: this.props.color ? this.props.color : '#080B0B' } }
                    >
                        { this.props.children }
                    </h3>
                }
            </VisibilitySensor>
        )   
    }
}

export default Title;