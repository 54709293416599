import React from 'react';
import classes from './Hero.module.scss';
import Button from '../../Button/Button';

import background from '../../../assets/img/services-hero-bg.svg';
import stand from '../../../assets/img/services-hero-stand.png';
import bigGlass  from '../../../assets/img/services-hero-big-glass.svg';
import smallGlass from '../../../assets/img/services-hero-small-glass.svg';
import leftBottle from '../../../assets/img/services-hero-bottle-left.svg';
import middleBottle from '../../../assets/img/services-hero-bottle-middle.svg';
import rightBottle from '../../../assets/img/services-hero-bottle-right.svg';
import skull from '../../../assets/img/services-hero-skull.svg';

const Hero = (props) => (
    <div className={ classes.hero }>
        <div className={ classes.image }>
            <img src={ background } alt="" className={ classes.background } />
            <img src={ stand } alt="" className={ classes.stand } />
            <img src={ bigGlass } alt="" className={ classes.bigGlass } />
            <img src={ smallGlass } alt="" className={ classes.smallGlass } />
            <img src={ leftBottle } alt="" className={ classes.leftBottle } />
            <img src={ middleBottle } alt="" className={ classes.middleBottle } />
            <img src={ rightBottle } alt="" className={ classes.rightBottle } />
            <img src={skull} alt="" className={ classes.skull } />
        </div>
        <div className={ classes.info }>
            <h1 className={ classes.title }>
                { props.title }
            </h1>
            <p className={ classes.text } dangerouslySetInnerHTML={ { __html: props.description } } />
            { props.isCorporate && <Button text={ props.buttonText } callMyBoss clicked={ props.bossButtonClicked } /> }
        </div>
    </div>
)

export default Hero;