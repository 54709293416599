import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Faq.module.scss';
import * as actions from '../../store/actions';
import axios from 'axios';
import CallToAction from '../../components/CallToAction/CallToAction';
import FaqSection from './FaqSection/FaqSection';
import ReactGA from 'react-ga';

// assets

import background from '../../assets/img/faq-bg.svg';
import cloud from '../../assets/img/faqCloud.svg';
import cap from '../../assets/img/faqHat.svg';
import wand from '../../assets/img/faqWand.svg';
import cvitanna from '../../assets/img/cvitanna.png';

class Faq extends Component {

    state = {
        pageContent: [],
        faqItems: [],
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        
        axios.get('https://api.engmonsters.in.ua/faq_page/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            const faqItems = Object.keys(response.data.faq_blocks).map(i => response.data.faq_blocks[i]);
            this.setState({
                pageContent: response.data.texts,
                faqItems: faqItems,
                seo: response.data.seo
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.lang !== prevProps.lang) {
            axios.get('https://api.engmonsters.in.ua/faq_page/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                const faqItems = Object.keys(response.data.faq_blocks).map(i => response.data.faq_blocks[i]);
                this.setState({
                    pageContent: response.data.texts,
                    faqItems: faqItems,
                    seo: response.data.seo
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    showModal = () => {
        this.props.showModal()
    }

    render() {

        const sections = this.state.faqItems.map((item, index) => (
            <FaqSection
                key={ index } 
                index={ index }
                title={ item.name }
                items={ item.items }
             />
        ))

        return (
            <div className={ classes.faq }>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <div className={ classes.hero }>
                    <div className={ classes.info }>
                        <div className={ classes.title }>
                            { this.state.pageContent.header }
                        </div>
                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: this.state.pageContent.header_desc } } />
                    </div>
                    <div className={ classes.image }>
                        <img src={ background } alt="" className={ classes.background } />
                        <img src={ cvitanna } alt="" className={ classes.cvitanna } />
                        <img src={ cap } alt="" className={ classes.cap } />
                        <img src={ wand } alt="" className={ classes.wand } />
                        <img src={ cloud } alt="" className={ classes.cloud } />
                        <div className={ classes.cloudText }>
                            { this.props.lang === 'ukr' ? 'Круціатус!' : 'Круциатус!' }
                        </div>
                    </div>
                </div>
                <div className={ classes.faqWrapper }>
                    { sections }
                </div>
                <CallToAction 
                    title={ this.state.pageContent.call_to_action_header }
                    subtitle={ this.state.pageContent.call_to_action_desc }
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}
  

export default connect(mapStateToProps, mapDispatchToProps)(Faq);