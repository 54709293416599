import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import classes from './Services.module.scss';
import Hero from './Hero/Hero';
import SliderButton from '../SliderButton/SliderButton';
import Individual from './Individual/Individual';
import Corporate from './Corporate/Corporate';
import * as actions from '../../store/actions';
import Title from '../Title/Title';
import CallToAction from '../CallToAction/CallToAction';
import OtherServices from './OtherServices/OtherServices';
import axios from 'axios';
import ReactGA from 'react-ga';

const servicesTypes = {
    individual: 'individual',
    corporate: 'corporate'
}

class Services extends Component {

    state={
        selectedType: servicesTypes.individual,
        pageContent: {},
        companies: [],
        otherServices: [],
        individualContent: null,
        corporateContent: null,
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);

        axios.get('https://api.engmonsters.in.ua/service_page/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            this.setState({
                pageContent: response.data.texts,
                companies: response.data.companies,
                otherServices: response.data.others,
                individualContent: response.data.for_you,
                corporateContent: response.data.for_brands,
                seo: response.data.seo
            })

        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lang !== this.props.lang) {
            axios.get('https://api.engmonsters.in.ua/service_page/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    pageContent: response.data.texts,
                    companies: response.data.companies,
                    otherServices: response.data.others,
                    individualContent: response.data.for_you,
                    corporateContent: response.data.for_brands,
                    seo: response.data.seo
                })

            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    toggleType = (type) => {
        this.setState({
            selectedType: type
        })
    }

    showModal = () => {
        this.props.showModal()
    }

    showCorporateModal = () => {
        this.props.showCorporateModal()
    }

    render() {
        return(
            <div className={ classes.services }>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <Hero 
                    isCorporate={ this.state.selectedType === servicesTypes.corporate } 
                    title={ this.state.pageContent.header }
                    description={ this.state.pageContent.header_desc }
                    buttonText={ this.state.pageContent.call_to_action_btn }
                    bossButtonClicked={ this.showCorporateModal }
                />
                <SliderButton
                    selectedType={ this.state.selectedType }
                    servicesTypes={ servicesTypes }
                    onTypeToggle={ (type) => this.toggleType(type) }
                    lang={ this.props.lang }
                />
                { this.state.selectedType === servicesTypes.individual 
                    ? (
                        <Individual 
                            lang={ this.props.lang } 
                            content={ this.state.individualContent }
                            onlineTitle={ this.state.pageContent.on_or_off_header }
                            onlineDescription={ this.state.pageContent.on_or_off_desc }
                            priceIncludesTitle={ this.state.pageContent.price_insert_header }
                        /> 
                    ) : (
                        <Corporate 
                            lang={ this.props.lang } 
                            content={ this.state.corporateContent } 
                            companiesTitle={ this.state.pageContent.companies_header }
                            companies={ this.state.companies }
                        />
                    )  
                }
                <div className={ classes.otherServices }>
                    <Title center>
                        { this.state.pageContent.other_header }
                    </Title>
                    <OtherServices services={ this.state.otherServices } />
                </div>
                <CallToAction 
                    title={ this.state.pageContent.call_to_action_header }
                    subtitle={ this.state.pageContent.call_to_action_desc }
                    lang={ this.props.lang }
                    showModal={ this.showModal } 
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal()),
        showCorporateModal: () => dispatch(actions.showCorporateModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);