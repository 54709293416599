import React, { Component } from 'react';
import classes from './MonstersSlider.module.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import labelBackground from '../../../assets/img/moster-slider-label.png';
import cn from 'classnames'

const NextArrow = (props) => <div onClick={ props.onClick } className={ classes.nextArrow }>&#62;</div>
const PrevArrow = (props) => <div onClick={ props.onClick } className={ classes.prevArrow }>&#60;</div>

class MonstersSlider extends Component {

    state = {
        activeSlide: 0,
        navSlider: null,
        slider: null
    }

    handleMonsterClick = (index) => {
        this.slider.slickGoTo(index);
    }

    componentDidMount() {
        this.setState({
            navSlider: this.navSlider,
            slider: this.slider
        })
    }

    render() {

        const slides = this.props.monsters && this.props.monsters.map((monster, index) => (
            <div key={ index }>
                <div className={ classes.slide }>
                    <img src={ monster.pic } alt="" className={ classes.photo } />
                    <div className={ classes.info }>
                        <div className={ classes.header }>
                            <div className={ classes.name }>{ monster.name }</div>
                            <div className={ classes.position }>
                                <img src={ labelBackground } alt=""  className={ classes.labelBackground } />
                                { monster.type_monster }
                            </div>
                        </div>
                        <div className={ classes.description } dangerouslySetInnerHTML={ { __html: monster.description } } />
                    </div>
                </div>
            </div>
        ))

        const navMonsters = this.props.monsters && this.props.monsters.map((monster, index) => (
            <div 
                className={ cn(classes.navMonster, {
                    [classes.active]: index === this.state.activeSlide
                }) } 
                key={ index }
                // onClick={ () => this.handleMonsterClick(index) }
            >
                <img className={ cn(classes.navMonsterImg, 'nav-monster-img') } alt="" src={ monster.pic } />
                <img className={ cn(classes.navMonsterImgHover, 'nav-monster-img-hover') } alt="" src={ monster.pic_hover } />
            </div>
        ))

        const settings = {
            adaptiveHeight: true,
            dots: true,
            infinite: true,
            arrows: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            dotsClass: 'services-slick-dots',
            beforeChange: (current, next) => this.setState({ activeSlide: next }),
        }

        const navSettings = {
            adaptiveHeight: false,
            dots: false,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 10
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 7
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 5
                    }
                }
            ]
        }

        return (
            <div className={ classes.monstersSlider }>
                <div className={ classes.navMonsters }>
                    <Slider
                        asNavFor={ this.state.slider }
                        ref={ navSlider => this.navSlider = navSlider }
                        { ...navSettings }
                    >
                        { navMonsters }
                    </Slider>
                </div>
                <div className={ classes.slider }>
                    <Slider 
                        asNavFor={ this.state.navSlider }
                        ref={ slider => this.slider = slider }
                        { ...settings }
                    >
                        { slides }
                    </Slider>
                </div>
            </div>
        )
    }
}

export default MonstersSlider;