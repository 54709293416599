import React from 'react';
import classes from './Input.module.scss';
import input from '../../assets/img/input.svg';
import inputMobile from '../../assets/img/input-mobile.svg';
import InputMask from 'react-input-mask';

const Input = (props) => {
    return (
        <div className={ classes.input }>
            <img src={ window.innerWidth > 650 ? input : inputMobile } alt="" />
            { props.withMask ? (
                <InputMask mask="+38(999) 999 99 99" value= {props.value } onChange={ (event) => props.onInputChange(event) }>
                    { () => (
                        <input 
                            type="text" 
                            placeholder={ props.placeholder }
                        />
                    ) }
                </InputMask>
            ) : (
                <input 
                    type="text" 
                    placeholder={ props.placeholder }
                    value={ props.value }
                    onChange={ props.onInputChange }
                />
            ) }
        </div>
    )
}

export default Input;