import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './PageNotFound.module.scss';
import ReactGA from 'react-ga';
import image from '../../assets/img/404.svg';
import Footer from '../Footer/Footer';

class PageNotFound extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
    }

    render() {
        return (
            <>
                <div className={ classes.wrapper }>
                    <Helmet>
                        <title>{ this.props.lang === 'ukr' ? "Такої сторінки немає" : 'Такой страницы не существует' } | English Monsters</title>
                    </Helmet>
                    <img src={ image } alt="" className={ classes.icon } />
                    <div className={ classes.title }>
                        { this.props.lang === 'ukr' 
                        ? (
                            <>
                                <div className={ classes.title }>
                                    Ой лишенько! Здається, такої сторінки немає((
                                </div>
                                <div className={ classes.text }>
                                    Але якщо ти вже тут, давай вивчимо нове слово:
                                    <br/>
                                    <span className={ classes.green }>You rock!</span> – <span className={ classes.yellow }>Ти молодець</span> – <span className={ classes.green }>Юрок</span> завжди <span className={ classes.yellow }>молодець!</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={ classes.title }>
                                    Кажется, такой страницы не существует((
                                </div>
                                <div className={ classes.text }>
                                    Но раз ты уже здесь, давай выучим новое слово:
                                    <br/>
                                    <span className={ classes.green }>You rock!</span> – <span className={ classes.yellow }>Ти молодец</span> – <span className={ classes.green }>Юрок</span> всегда <span className={ classes.yellow }>молодец!</span>
                                </div>
                            </>
                        ) }
                    </div>
                </div>
                <Footer lang={ this.props.lang } />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}  

export default connect(mapStateToProps)(PageNotFound);