import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Header.module.scss';
import logo from '../../assets/img/logo.svg';
import Button from '../Button/Button';
import MenuItem from './MenuItem/MenuItem';
import methodsMonster from '../../assets/img/methods-monster.svg';
import servicesMonster from '../../assets/img/services-monster.svg';
import calendarMonster from '../../assets/img/calendar-monster.svg';
import monstersMonster from '../../assets/img/monsters-monster.svg';
import TypeSwitch from '../TypeSwitch/TypeSwitch';
import cn from 'classnames';
import MenuButton from './MenuButton/MenuButton';

const Header = (props) => {

    const servicesLabel = props.lang === 'ukr' ? 'Послуги' : 'Услуги'
    const calendarLabel = props.lang === 'ukr' ? 'Календар' : 'Календарь'
    const monstersLabel = props.lang === 'ukr' ? 'Монстри' : 'Монстры'

    return(
        <div className={ classes.header }>
            <div className={ cn(classes.wrapper, classes.clearfix) }>
                <Link to="/" className={ classes.logo }>
                    <img src={ logo } alt="" />
                </Link>
                <TypeSwitch inHeader />
                <Button callButton right inHeader clicked={ props.showModal } />
                <MenuItem link={ 'monsters' } img={ monstersMonster } monstersLink>{ monstersLabel }</MenuItem>
                <MenuItem link={ 'calendar' } img={ calendarMonster }>{ calendarLabel }</MenuItem>
                <MenuItem link={ 'services' } img={ servicesMonster }>{ servicesLabel }</MenuItem>
                <MenuItem link={ 'methods' } img={ methodsMonster }>Методика</MenuItem>
                { window.innerWidth <= 1100 && 
                    <MenuButton 
                        isOpened={ props.isMenuOpened } 
                        openClicked={ props.menuButtonClicked } 
                        closeClicked={ props.closeMenuButtonClicked }
                    /> 
                }
            </div>
        </div>
    )
}

export default Header;