import React, { Component } from 'react';
import classes from './Product.module.scss';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import Button from '../../Button/Button';

class Product extends Component {
    state = {
        isInvisible: true,
    }

    makeProductVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isInvisible: false
            })
        }
    }

    render() {
        return(
            <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isInvisible } onChange={ this.makeProductVisible }>
                {
                    ({ isVisible }) => 
                        <div 
                            className={ cn(classes.product, {
                                [classes.right]: this.props.right,
                                [classes.visible]: isVisible
                            }) }
                        >
                            <div className={ classes.title }>
                                { this.props.title }
                            </div>
                            <div className={ classes.text }>
                                { this.props.text }
                            </div>
                            <img src={ this.props.photo } alt="" className={ classes.photo } />
                            <div className={ classes.price }>
                                { this.props.price } <span>грн</span>
                            </div>
                            <Button
                                buyButton
                                clicked={ this.props.clicked }
                                left={ window.innerWidth > 1100 ? true : false }
                             />
                        </div>
                }
            </VisibilitySensor>
        )
    }
}

export default Product