import React, { PureComponent } from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import classes from './Blog.module.scss';
import PostLink from './PostLink/PostLink';
import axios from 'axios';
import Search from './Search/Search';
import Category from './Category/Category';
import CallToAction from '../CallToAction/CallToAction';
import ReactGA from 'react-ga';

class Blog extends PureComponent {

    state = {
        posts: [],
        categories: [
            {
                url: 'all',
                name: this.props.lang === 'ukr' ? 'Всі' : 'Все' 
            }
        ],
        currentUrl: null,
        search: '',
        postsCount: null
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        let currentCategory = this.props.match.params.category

        axios.get('https://api.engmonsters.in.ua/blog/categories/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {

            this.setState({
                categories: this.state.categories.concat(response.data),
                currentUrl: currentCategory
            })
        })

        if(currentCategory === 'all'){
            axios.get('https://api.engmonsters.in.ua/blog/all/', {
                params: {
                    lang: this.props.lang,
                    p: 1
                }
            })
            .then(response => {
                this.setState({
                    posts: response.data.posts,
                    currentUrl: currentCategory,
                    postsCount: response.data.posts_count
                })
            })
        } else {
            axios.get('https://api.engmonsters.in.ua/blog/category/', {
                params: {
                    lang: this.props.lang,
                    url: currentCategory,
                    p: 1
                }
            })
            .then(response => {
                this.setState({
                    posts: response.data.posts,
                    currentUrl: currentCategory,
                    postsCount: response.data.posts_count
                })
            })
        }
    }

    componentDidUpdate (prevProps, prevState) {
        let currentCategory = this.props.match.params.category

        if(prevProps.lang !== this.props.lang) {
            axios.get('https://api.engmonsters.in.ua/blog/categories/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    categories: this.state.categories.concat(response.data),
                    currentUrl: currentCategory
                })
            })

            if(currentCategory === 'all'){
                axios.get('https://api.engmonsters.in.ua/blog/all/', {
                    params: {
                        lang: this.props.lang,
                        p: 1
                    }
                })
                .then(response => {
                    this.setState({
                        posts: response.data.posts,
                        currentUrl: currentCategory,
                        postsCount: response.data.posts_count
                    })
                })
            } else {
                axios.get('https://api.engmonsters.in.ua/blog/category/', {
                    params: {
                        lang: this.props.lang,
                        url: currentCategory,
                        p: 1
                    }
                })
                .then(response => {
                    this.setState({
                        posts: response.data.posts,
                        currentUrl: currentCategory,
                        postsCount: response.data.posts_count
                    })
                })
            }
        } else if(prevState.currentUrl !== currentCategory) {
            if(currentCategory === 'all'){
                axios.get('https://api.engmonsters.in.ua/blog/all/', {
                    params: {
                        lang: this.props.lang,
                        p: 1
                    }
                })
                .then(response => {
                    this.setState({
                        posts: response.data.posts,
                        currentUrl: currentCategory,
                        postsCount: response.data.posts_count
                    })
                })
            } else {
                axios.get('https://api.engmonsters.in.ua/blog/category/', {
                    params: {
                        lang: this.props.lang,
                        url: currentCategory,
                        p: 1
                    }
                })
                .then(response => {
                    this.setState({
                        posts: response.data.posts,
                        currentUrl: currentCategory,
                        postsCount: response.data.posts_count
                    })
                })
            }
        }
    }


    handleSearchInput = (event) => {
        this.setState({
            search: event.target.value
        })
    }

    handleSearchClear = () => {
        this.setState({
            search: ''
        })
    }

    handleSearchSubmit = (event) => {
        event.preventDefault()
        axios.get('https://api.engmonsters.in.ua/blog/search/', {
            params: {
                lang: this.props.lang,
                s: this.state.search
            }
        })
        .then(response => {
            this.setState({
                categories: response.data
            })
        })
    }
    
    render() {
        const categories = this.state.categories && this.state.categories.map(category => (
            <Category
                key={ category.url }
                active={ category.url === this.state.currentUrl }
                url={ category.url }
                name={ category.name }
            />
        ))

        const posts = this.state.posts && this.state.posts.map(post => (
            <PostLink 
                key={ post.url }
                url={ post.url }
                photo={ post.pic }
                title={ post.header }
                text={ post.description }
            /> 
        ))

        return(
            <div className={ classes.blog }>
                <Helmet>
                    <title>Блог | English Monsters</title>
                </Helmet>
                <div className={ classes.wrapper }>
                    <div className={ classes.hero }>
                        <div className={ classes.title }>Блог</div>
                        <Search
                            value={ this.state.search }
                            lang={ this.props.lang }
                            changed={ (event) => this.handleSearchInput(event) }
                            clearInput={ this.handleSearchClear }
                            submited={ (event) => this.handleSearchSubmit(event) }
                        />
                    </div>
                    <div className={ classes.categories }>
                        <div className={ classes.categoriesWrp }>
                            <div className={ classes.categoriesScrollContainer }>
                                { categories }  
                            </div>
                        </div>
                        <div className={ classes.count }>
                            { this.state.postsCount && `${ this.state.postsCount } Статей` }
                        </div>
                    </div>
                    <div className={ classes.posts }>
                        { posts }
                    </div>
                </div>
                <CallToAction 
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);

