import React, { Component } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from './OtherServices.module.scss';
import { Link } from 'react-router-dom';

import dollarYellowIcon from '../../../assets/img/dollar-yellow.svg'
import dollarGreenIcon from '../../../assets/img/dollar-green.svg'
import dollarRedIcon from '../../../assets/img/dollar-red.svg'

import clockYellowIcon from '../../../assets/img/clock-yellow.svg'
import clockGreenIcon from '../../../assets/img/clock-green.svg'
import clockRedIcon from '../../../assets/img/clock-red.svg'

import pinRedIcon from '../../../assets/img/pin-red.svg'
import pinYellowIcon from '../../../assets/img/pin-yellow.svg'
import pinGreenIcon from '../../../assets/img/pin-green.svg'

import personYellowIcon from '../../../assets/img/person-yellow.svg';
import personRedIcon from '../../../assets/img/person-red.svg';
import personGreenIcon from '../../../assets/img/person-green.svg';

const NextArrow = (props) => <div onClick={ props.onClick } className={ classes.nextArrow }>&#62;</div>
const PrevArrow = (props) => <div onClick={ props.onClick } className={ classes.prevArrow }>&#60;</div>

class OtherServices extends Component {

    componentDidUpdate() {
        this.slider.slickGoTo(0);
    }

    renderSlides = () => {
        return this.props.services.map((service, index) => {

            let dollarIcon
            let clockIcon
            let pinIcon
            let personIcon

            const isLink = service.url ? true : false;

            if(service.color === '#FDCF41') {
                dollarIcon = dollarYellowIcon
                clockIcon = clockYellowIcon
                pinIcon = pinYellowIcon
                personIcon = personYellowIcon
            } else if(service.color === '#F55428') {
                dollarIcon = dollarRedIcon
                clockIcon = clockRedIcon
                pinIcon = pinRedIcon
                personIcon = personRedIcon
            } else if(service.color === '#91D1C5'){
                dollarIcon = dollarGreenIcon
                clockIcon = clockGreenIcon
                pinIcon = pinGreenIcon
                personIcon = personGreenIcon
            }

            return (
                <div className={ classes.slideOuter } key={ index }>
                    <div className={ classes.slide }>
                        { 
                            isLink ? (
                                <Link to={ `/service/other/${ service.url }` } className={ classes.iconContainer }>
                                    <img src={ service.pic } alt="" />
                                </Link>
                            ) : (
                                <div className={ classes.iconContainer }>
                                    <img src={ service.pic } alt="" />
                                </div>
                            )
                        }
                        <div 
                            className={ classes.serviceNum } 
                            style={ { color: service.color } }
                        >
                            Послуга { index + 1 }
                        </div>
                        {
                            isLink ? (
                                <Link to={ `/service/other/${ service.url }` } className={ classes.title }>
                                    { service.name }
                                </Link>
                            ) : (
                                <div className={ classes.title }>
                                    { service.name }
                                </div>
                            )
                        }
                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: service.desc } } />
                        <div className={ classes.details }>
                            { service.price !== '' && 
                                <div className={ classes.price } style={ { color: service.color } }>
                                    <img src={ dollarIcon } alt="" />
                                    { service.price }
                                </div>
                            }
                            { service.time !== '' &&
                                <div className={ classes.time } style={ { color: service.color } }>
                                    <img src={ clockIcon } alt="" />
                                    { service.time }
                                </div>
                            }
                            { service.place !== '' &&
                                <div className={ classes.place } style={ { color: service.color } }>
                                    <img src={ pinIcon } alt="" />
                                    { service.place }
                                </div>
                            }
                            { service.people !== '' &&
                                <div className={ classes.person } style={ { color: service.color } }>
                                    <img src={ personIcon } alt="" />
                                    { service.people }
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        })
    }

    render() {
        const settings = {
            adaptiveHeight: true,
            dots: true,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            dotsClass: 'services-slick-dots',
        }

        return(
            <div className={ classes.slider }>
                <Slider ref={ slider => this.slider = slider } { ...settings }>
                    { this.renderSlides() }
                </Slider>
            </div>
        )
    }
}

export default OtherServices;