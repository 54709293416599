import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Methodology.module.scss';
import * as actions from '../../store/actions';
import axios from 'axios';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import book from '../../assets/img/metod-book.png'
import Title from '../Title/Title';
import YouTube from 'react-youtube';
import CallToAction from '../CallToAction/CallToAction';
import ReactGA from 'react-ga';

// assets

import manImg from '../../assets/img/method-man.svg';
import moreImg from '../../assets/img/method-more.svg';

import refreshIcon from '../../assets/img/method-refresh-icon.svg';
import pIcon from '../../assets/img/method-p-icon.svg';
import arrowsIcon from '../../assets/img/method-arrows-icon.svg';
import rulerIcon from '../../assets/img/method-ruler-icon.svg';
import wallIcon from '../../assets/img/method-wall-icon.svg';
import largeBraceIcon from '../../assets/img/method-large-brace-icon.svg';
import smallBraceIcon from '../../assets/img/method-small-brace-icon.svg';

import pug from '../../assets/img/mops.png';
import cloud from '../../assets/img/mops-cloud.svg';
import topTear from '../../assets/img/mops-tear-top.svg';
import bottomTear from '../../assets/img/mops-tear-bottom.svg';

import mrBean from '../../assets/img/mr-bean.png';
import chandler from '../../assets/img/chandler.png';

import city from '../../assets/img/city.png';
import largeBanana from '../../assets/img/large-banana.svg';
import smallBanana from '../../assets/img/small-banana.svg';
import cityDrips from '../../assets/img/city-drips.svg';

import videoMonster from '../../assets/img/video-monster.svg';
import videoDrips from '../../assets/img/video-drips.svg';




class Methodology extends Component {

    state = {
        pageContent: {},
        methods: [],
        isFirstInvisible: true,
        isSecondInvisible: true,
        isThirdInvisible: true,
        isFourthInvisible: true,
        isFifthInvisible: true,
        isSixInvisible: true,
        isLawFirmInvisible: true,
        videoId: null,
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);

        axios.get('https://api.engmonsters.in.ua/methodics/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            const methods = Object.keys(response.data.methods).map(i => response.data.methods[i]);
            let transformedVideoId = this.youTubeGetID(methods[1].video_url)
            this.setState({
                pageContent: response.data.texts,
                methods: methods,
                videoId: transformedVideoId,
                seo: response.data.seo
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.lang !== prevProps.lang) {
            axios.get('https://api.engmonsters.in.ua/methodics/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                const methods = Object.keys(response.data.methods).map(i => response.data.methods[i]);
                let transformedVideoId = this.youTubeGetID(methods[1].video_url)
                this.setState({
                    pageContent: response.data.texts,
                    methods: methods,
                    videoId: transformedVideoId,
                    seo: response.data.seo
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    makeFirstMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFirstInvisible: false
            })
        }
    }

    makeSecondMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isSecondInvisible: false
            })
        }
    }

    makeThirdMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isThirdInvisible: false
            })
        }
    }

    makeFourthMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFourthInvisible: false
            })
        }
    }

    makeFifthMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFifthInvisible: false
            })
        }
    }

    makeSixMethodVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isSixInvisible: false
            })
        }
    }

    makeLawFirmVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isLawFirmInvisible: false
            })
        }
    }

    showModal = () => {
        this.props.showModal()
    }

    youTubeGetID = (url) => {
        let ID = '';
        url = url.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        if(url[2] !== undefined) {
            ID = url[2].split(/[^0-9a-z_-]/i);
            ID = ID[0];
        }
        else {
            ID = url;
        }
        return ID;
    }

    render() {
        
        let videoOptions = {
            width: '586',
            height: '328'
        }

        let title = this.props.lang === 'ukr' ? 'Монстроінструменти' : 'Монстроинструменты'

        if(window.innerWidth <= 1100) {
            title = this.props.lang === 'ukr' ? 'Монстро - інструменти' : 'Монстро - инструменты'
        }

        if(window.innerWidth <= 650) {
            videoOptions = {
                width: '288',
                height: '160'
            }
        } else if(window.innerWidth <= 1300) {
            videoOptions = {
                width: '512',
                height: '286'
            }
        }

        return(
            <div className={ classes.methodology }>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <div className={ classes.wrapper }>
                    <div className={ classes.hero }>
                        <div className={ classes.info }>
                            <div className={ classes.title }>
                                { this.state.pageContent.header }
                            </div>
                            <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.pageContent.header_desc } } />
                        </div>
                        <div className={ classes.image }>
                            <svg className={ classes.background } width="640" height="638" viewBox="0 0 640 638" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M84.8104 382.25C118.362 462.4 128.807 519.75 304.807 530.75C480.807 541.75 551.75 496.84 549.81 349.25C547.87 201.66 525.416 72.5296 304.807 85.2501C84.1982 97.9705 51.2591 302.1 84.8104 382.25Z" fill="#91D1C5"/>
                            </svg>
                            <img src={ book } alt="" className={ classes.book } />
                        </div>
                    </div>
                    <Title center>{ title }</Title>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFirstInvisible } onChange={ this.makeFirstMethodVisible }>
                        {
                            ({ isVisible }) => 
                            <div className={ cn(classes.method, classes.first, {
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.image }>
                                    <svg className={ classes.background } width="756" height="770" viewBox="0 0 756 770" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M612.5 612.5C503.499 726 225.918 701.506 134.001 642C38.5718 580.22 56.9989 298.394 116.001 191.5C175.003 84.6058 318.663 30.9673 539.503 96.5C708 146.5 721.501 499 612.5 612.5Z" fill="#FDCF41"/>
                                    </svg>
                                    <div className={ classes.topPart }>
                                        <img src={ manImg } alt=""/>
                                        <div className={ classes.topText }>
                                            { this.props.lang === 'ukr' ? (
                                                <>
                                                    Це <span>ер</span>н<span>eст</span>, він маленький. Тому йому пасують лише маленькі прикметники
                                                </>
                                            ) :  (
                                                <>
                                                    Это <span>ЭР</span>н<span>EСТ</span>, он маленький. Поэтому ему подходят только маленькие прилагательные
                                                </>
                                            )}
                                        </div>
                                        <div className={ classes.bottomText }>
                                            Soft - Soft<span>er</span> - <span>The</span> soft<span>est</span>
                                        </div>
                                    </div>
                                    <div className={ classes.bottomPart }>
                                        <div className={ classes.topText }>
                                            { this.props.lang === 'ukr' ? (
                                                <>
                                                    Це <span>міст</span>. <span>Мост</span>и через <span>море</span> мали бути великими. Тому це для великих прикметників
                                                </>
                                            ) : (
                                                <>
                                                    Это <span>МОСТ</span>. <span>МОСТ</span>ы через <span>МОРЕ</span> должны быть очень большими. Поэтому это для больших прилагательных
                                                </>
                                            ) }
                                        </div>
                                        <div className={ classes.bottomText }>popular - <span>More</span> popular - <span>The most</span> popular</div>
                                        <img src={ moreImg } alt="" /> 
                                    </div>
                                </div>
                                <div className={ classes.info }>
                                    <div className={ classes.methodNum }>Метод 1</div>
                                    <div className={ classes.title }>
                                        { this.state.methods.length && this.state.methods[0].name }
                                    </div>
                                    <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.methods.length && this.state.methods[0].desc } }  />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isSecondInvisible } onChange={ this.makeSecondMethodVisible }>
                        {
                            ({ isVisible }) => 
                            <div className={ cn(classes.method, classes.second, {
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.image }>
                                    <svg className={ classes.background } width="603" height="618" viewBox="0 0 603 618" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M532.061 403.988C504.664 515.391 297.398 572.47 193.738 546.977C80.1262 519.037 59.472 413.115 55.6505 299.104C50.3823 141.936 268.782 35.9008 372.443 61.3938C524.061 98.6807 559.458 292.584 532.061 403.988Z" fill="#91D1C5"/>
                                    </svg>
                                    <div className={ classes.topRow }>
                                        <div className={ classes.item }>
                                            <img src={ refreshIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                                { this.props.lang === 'ukr' ? 'повторювання' : 'Повторение' }
                                            </div>
                                        </div>
                                        <div className={ classes.item }>
                                            <img src={ pIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                                { this.props.lang === 'ukr' ? 'Що зро...?' : 'Что сде…?' }
                                            </div>
                                        </div>
                                        <div className={ classes.item }>
                                            <img src={ arrowsIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                            { this.props.lang === 'ukr' ? 'Що ро...?' : 'Что де…? ' }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={ classes.midRow }>
                                        <div className={ classes.item }>
                                            <img src={ rulerIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                                { this.props.lang === 'ukr' ? 'Лінійка' : 'Линейка' }
                                            </div>
                                        </div>
                                        <div className={ classes.item }>
                                            <img src={ wallIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                                { this.props.lang === 'ukr' ? 'Стіна' : 'Стена' }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={ classes.bottomRow }>
                                        <div className={ classes.item }>
                                            <img src={ largeBraceIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                                { this.props.lang === 'ukr' ? 'Велика дужка' : 'Большая скобка ' }
                                            </div>
                                        </div>
                                        <div className={ classes.item }>
                                            <img src={ smallBraceIcon } alt="" className={ classes.icon } />
                                            <div className={ classes.text }>
                                                { this.props.lang === 'ukr' ? 'Маленька дужка' : 'Маленькая скобка' }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={ classes.info }>
                                    <div className={ classes.methodNum }>Метод 2</div>
                                    <div className={ classes.title }>
                                        { this.state.methods.length && this.state.methods[1].name }
                                    </div>
                                    <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.methods.length && this.state.methods[1].desc } }  />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isThirdInvisible } onChange={ this.makeThirdMethodVisible }>
                        {
                            ({ isVisible }) => 
                            <div className={ cn(classes.method, classes.third, {
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.image }>
                                    <svg className={ classes.background } width="634" height="591" viewBox="0 0 634 591" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M545.798 408.248C509.109 498.313 266.907 507.512 165.002 465.999C53.3147 420.502 70.8613 326.194 76.4416 227.963C84.1344 92.5493 316.095 63.4872 418 105C567.05 165.717 582.487 318.184 545.798 408.248Z" fill="#F55428"/>
                                    </svg>     
                                    <img src={ pug } alt="" className={ classes.pug } />                 
                                    <img src={ cloud } alt="" className={ classes.pugCloud } />                 
                                    <img src={ topTear } alt="" className={ classes.pugTearTop } />                 
                                    <img src={ bottomTear } alt="" className={ classes.pugTearBottom } />    
                                    <div className={ cn(classes.pugCloudText, {
                                        [classes.rus]: this.props.lang === 'rus'
                                    }) }>
                                        { this.props.lang === 'ukr' ? 'Сам себе на частини діли!' : 'Сам себя на части дели!' }
                                    </div>             
                                </div>
                                <div className={ classes.info }>
                                    <div className={ classes.methodNum }>Метод 3</div>
                                    <div className={ classes.title }>
                                        { this.state.methods.length && this.state.methods[2].name }
                                    </div>
                                    <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.methods.length && this.state.methods[2].desc } }  />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFourthInvisible } onChange={ this.makeFourthMethodVisible }>
                        {
                            ({ isVisible }) => 
                            <div className={ cn(classes.method, classes.fourth, {
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.image }>
                                    <div className={ classes.topLine }>
                                        <div>Have +</div>
                                        <img src={ mrBean } alt="" className={ classes.mrBean } />
                                        <div>+ 3F</div>
                                    </div>
                                    <div className={ classes.bottomLine }>
                                        <div>Be +</div>
                                        <img src={ chandler } alt="" className={ classes.chandler } />
                                        <div>+ 3F</div>
                                    </div>
                                </div>
                                <div className={ classes.info }>
                                    <div className={ classes.methodNum }>Метод 4</div>
                                    <div className={ classes.title }>
                                        { this.state.methods.length && this.state.methods[3].name }
                                    </div>
                                    <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.methods.length && this.state.methods[3].desc } }  />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFifthInvisible } onChange={ this.makeFifthMethodVisible }>
                        {
                            ({ isVisible }) => 
                            <div className={ cn(classes.method, classes.fifth, {
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.image }>
                                    <img src={ city } alt="" className={ classes.city } />
                                    <img src={ smallBanana } alt="" className={ classes.smallBanana } />
                                    <img src={ largeBanana } alt="" className={ classes.largeBanana } />
                                    <img src={ cityDrips } alt="" className={ classes.cityDrips } />
                                </div>
                                <div className={ classes.info }>
                                    <div className={ classes.methodNum }>Метод 5</div>
                                    <div className={ classes.title }>
                                        { this.state.methods.length && this.state.methods[4].name }
                                    </div>
                                    <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.methods.length && this.state.methods[4].desc } }  />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isSixInvisible } onChange={ this.makeSixMethodVisible }>
                        {
                            ({ isVisible }) => 
                            <div className={ cn(classes.method, classes.sixth, {
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.video }>
                                    <YouTube 
                                        videoId={ this.state.videoId }
                                        opts={ videoOptions }
                                    />
                                    <img src={ videoMonster } alt="" className={ classes.videoMonster } />
                                    <img src={ videoDrips } alt="" className={ classes.videoDrips } />
                                </div>
                                <div className={ classes.info }>
                                    <div className={ classes.methodNum }>Метод 6</div>
                                    <div className={ classes.title }>
                                        { this.state.methods.length && this.state.methods[5].name }
                                    </div>
                                    <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.methods.length && this.state.methods[5].desc } }  />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isLawFirmInvisible } onChange={ this.makeLawFirmVisible }>
                        {
                            ({ isVisible }) =>
                            <div className={ cn(classes.lawFirm, {
                                [classes.visible]: isVisible
                            }) }>
                                { this.props.lang === 'ukr' ? (
                                    <span>Юридичний захист надає <a href="https://bargen.com.ua/" target="_blank" rel="noopener noreferrer">Bargen Law Firm</a></span>
                                ) : (
                                    <span>Юридическую защиту предоставляет <a href="https://bargen.com.ua/" target="_blank" rel="noopener noreferrer">Bargen Law Firm</a></span>
                                )}
                            </div>
                        }
                    </VisibilitySensor>
                </div>
                <CallToAction 
                    title={ this.state.pageContent.call_to_action_header }
                    subtitle={ this.state.pageContent.call_to_action_desc }
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Methodology);