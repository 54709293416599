import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classes from './Individual.module.scss';
import Title from '../../Title/Title';
import VisibilitySensor from 'react-visibility-sensor';
import cn from 'classnames'

// assets

import individualPortrait from '../../../assets/img/services-portrait-individual.png'
import corporatePortrait from '../../../assets/img/services-portrait-corporate.png'

import dollarYellowIcon from '../../../assets/img/dollar-yellow.svg'
import dollarRedIcon from '../../../assets/img/dollar-red.svg'
import clockYellowIcon from '../../../assets/img/clock-yellow.svg'
import clockRedIcon from '../../../assets/img/clock-red.svg'
import pinYellowIcon from '../../../assets/img/pin-yellow.svg'
import pinRedIcon from '../../../assets/img/pin-red.svg'
import personYellowIcon from '../../../assets/img/person-yellow.svg';
import personRedIcon from '../../../assets/img/person-red.svg';

import includesTvIcon from '../../../assets/img/includes-tv.svg';
import includesMaterialsIcon from '../../../assets/img/includes-materials.svg';
import includesDialogIcon from '../../../assets/img/includes-dialog.svg';
import includesClockIcon from '../../../assets/img/includes-clock.svg';
import includesSportIcon from '../../../assets/img/includes-sport.svg';
import includesStarIcon from '../../../assets/img/includes-star.svg';
import includesCircle from '../../../assets/img/includes-circle.svg';
import includesGlasses from '../../../assets/img/includes-glasses.svg';

import onlineOrOfflinePortrait from '../../../assets/img/online-or-offline-portrait.png';
import onlineOrOfflineBackground from '../../../assets/img/online-or-offline-bg.png';

class Individual extends Component {

    state = {
        isIndividualVisible: true,
        isCorporateVisible: true,
        isPriceSectionVisible: true,
        isOnlineSectionVisible: true
    }

    makeIndividualVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isIndividualVisible: false
            })
        }
    }

    makeCorporateVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isCorporateVisible: false
            })
        }
    }

    makePriceSectionVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isPriceSectionVisible: false
            })
        }
    }

    makeOnlineSectionVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isOnlineSectionVisible: false
            })
        }
    }


    render() {
        const onlineBadge = (
            <div className={ classes.badge }>
                <svg width="135" height="43" viewBox="0 0 135 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M135 24.3771C135 42.9158 102.754 43.3317 64.2513 42.9158C25.7487 42.4999 0 43.5841 0 21.7921C0 6.3953e-05 21.4978 0 74.393 0C121.742 0 135 2.48547 135 24.3771Z" fill="#F0582E"/>
                </svg>
                <div className={ classes.badgeText }>Online</div>
            </div>
        )
    
        const offlineBadge = (
            <div className={ classes.badge }>
                <svg width="136" height="43" viewBox="0 0 136 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M136 24.3771C136 42.9158 114.343 43.3317 75.5556 42.9158C36.7677 42.4999 0 43.5841 0 21.7921C0 6.41864e-05 6.21296 0 59.5 0C107.2 0 136 2.48547 136 24.3771Z" fill="#FDCF41"/>
                </svg>
                <div className={ classes.badgeText }>Offline</div>
            </div>
        )
    
        return (
            <>
                <div className={classes.wrapper}>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isIndividualVisible } onChange={ this.makeIndividualVisible } >
                            {
                                ({ isVisible }) => 
                                    <div className={ cn(classes.individual, {
                                        [classes.visible]: isVisible
                                    }) }>
                                        <div className={ classes.info }>
                                            { this.props.content && this.props.content[1].url ? (
                                                <Link to={`/service/individual/${ this.props.content[1].url }`} className={ classes.title }>
                                                    { this.props.content && this.props.content[1].name }
                                                </Link>
                                            ) : (
                                                <div className={ classes.title }>
                                                    { this.props.content && this.props.content[1].name }
                                                </div>
                                            ) }
                                            <div className={ classes.text } dangerouslySetInnerHTML={ { __html:  this.props.content && this.props.content[1].desc } } />
                                            <div className={ classes.details }>
                                                <div className={ classes.online }>
                                                    { onlineBadge }
                                                    { this.props.content && this.props.content[1].online_price && (
                                                        <div className={ classes.price }>
                                                            <img src={ dollarRedIcon } alt="" />
                                                            { this.props.content[1].online_price }
                                                        </div>
                                                    ) }
                                                    { this.props.content && this.props.content[1].online_time && (
                                                        <div className={ classes.time }>
                                                            <img src={ clockRedIcon } alt="" />
                                                            { this.props.content[1].online_time }
                                                        </div>
                                                    ) }
                                                    { this.props.content && this.props.content[1].online_place && (
                                                        <div className={ classes.place }>
                                                            <img src={ pinRedIcon } alt="" />
                                                            { this.props.content[1].online_place }
                                                        </div>
                                                    ) }
                                                    { this.props.content && this.props.content[1].online_people && (
                                                        <div className={ classes.person }>
                                                            <img src={ personRedIcon } alt="" />
                                                            { this.props.content[1].online_people }
                                                        </div>
                                                    ) }
                                                </div>
                                                <div className={ classes.offline }>
                                                    { offlineBadge }
                                                    { this.props.content && this.props.content[1].offline_price && (
                                                        <div className={ classes.price }>
                                                            <img src={ dollarYellowIcon } alt="" />
                                                            { this.props.content[1].offline_price }
                                                        </div>
                                                    ) }
                                                    { this.props.content && this.props.content[1].offline_time && (
                                                        <div className={ classes.time }>
                                                            <img src={ clockYellowIcon } alt="" />
                                                            { this.props.content[1].offline_time }
                                                        </div>
                                                    ) }
                                                    { this.props.content && this.props.content[1].offline_place && (
                                                        <div className={ classes.place }>
                                                            <img src={ pinYellowIcon } alt="" />
                                                            { this.props.content[1].offline_place }
                                                        </div>
                                                    ) }
                                                    { this.props.content && this.props.content[1].offline_people && (
                                                        <div className={ classes.person }>
                                                            <img src={ personYellowIcon } alt="" />
                                                            { this.props.content[1].offline_people }
                                                        </div>
                                                    ) }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={ classes.image }>
                                            <img src={ individualPortrait } alt="" />
                                        </div>
                                    </div>
                            }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isCorporateVisible } onChange={ this.makeCorporateVisible } >
                            {
                                ({ isVisible }) => 
                                    <div className={ cn(classes.corporate, {
                                        [classes.visible]: isVisible
                                    }) }>
                                    <div className={ classes.image }>
                                        <img src={ corporatePortrait } alt="" />
                                    </div>
                                    <div className={ classes.info }>
                                        { this.props.content && this.props.content[2].url ? (
                                            <Link to={`/service/individual/${ this.props.content[2].url }`} className={ classes.title }>
                                                { this.props.content && this.props.content[2].name }
                                            </Link>
                                        ) : (
                                            <div className={ classes.title }>
                                                { this.props.content && this.props.content[2].name }
                                            </div>
                                        ) }
                                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html:  this.props.content && this.props.content[2].desc } } />
                                        <div className={ classes.details }>
                                            <div className={ classes.online }>
                                                { onlineBadge }
                                                <div className={ classes.price }>
                                                    <img src={ dollarRedIcon } alt="" />
                                                    { this.props.content && this.props.content[2].online_price }
                                                </div>
                                                <div className={ classes.time }>
                                                    <img src={ clockRedIcon } alt="" />
                                                    { this.props.content && this.props.content[2].online_time }
                                                </div>
                                                <div className={ classes.place }>
                                                    <img src={ pinRedIcon } alt="" />
                                                    { this.props.content && this.props.content[2].online_place }
                                                </div>
                                                <div className={ classes.person }>
                                                    <img src={ personRedIcon } alt="" />
                                                    { this.props.content && this.props.content[2].online_people }
                                                </div>
                                            </div>
                                            <div className={ classes.offline }>
                                                { offlineBadge }
                                                <div className={ classes.price }>
                                                    <img src={ dollarYellowIcon } alt="" />
                                                    { this.props.content && this.props.content[2].offline_price }
                                                </div>
                                                <div className={ classes.time }>
                                                    <img src={ clockYellowIcon } alt="" />
                                                    { this.props.content && this.props.content[2].offline_time }
                                                </div>
                                                <div className={ classes.place }>
                                                    <img src={ pinYellowIcon } alt="" />
                                                    { this.props.content && this.props.content[2].offline_place }
                                                </div>
                                                <div className={ classes.person }>
                                                    <img src={ personYellowIcon } alt="" />
                                                    { this.props.content && this.props.content[2].offline_people }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                    </VisibilitySensor>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isPriceSectionVisible } onChange={ this.makePriceSectionVisible } >
                            {
                                ({ isVisible }) => 
                                    <div className={ cn(classes.includes, {
                                        [classes.visible]: isVisible
                                    }) }>
                                        <img src={ includesCircle } alt=""  className={ classes.circle } />
                                        <img src={ includesGlasses } alt=""  className={ classes.glasses } />
                                        <Title>
                                            { this.props.priceIncludesTitle }
                                        </Title>
                                        <div className={ classes.includesList }>
                                            <div className={ classes.includesItem }>
                                                <div className={ classes.iconContainer }>
                                                    <img src={ includesTvIcon } alt="" />
                                                </div>
                                                { this.props.lang === 'ukr' ? '24 заняття' : '24 Занятия' }
                                            </div>
                                            <div className={ classes.includesItem }>
                                                <div className={ classes.iconContainer }>
                                                    <img src={ includesMaterialsIcon } alt="" />
                                                </div>
                                                { this.props.lang === 'ukr' ? 'всі навчальні матеріали' : 'все учебные материалы' }
                                            </div>
                                            <div className={ classes.includesItem }>
                                                <div className={ classes.iconContainer }>
                                                    <img src={ includesDialogIcon } alt="" />
                                                </div>
                                                { this.props.lang === 'ukr' ? '12 спікінг-клабів у нас в офісі' : '12 спикинг-клабов у нас в офисе' }
                                            </div>
                                            <div className={ classes.includesItem }>
                                                <div className={ classes.iconContainer }>
                                                    <img src={ includesClockIcon } alt="" />
                                                </div>
                                                { this.props.lang === 'ukr' ? '30 перевірених аудіозаписів від кожного учня' : '30 проверенных аудиозаписей от каждого ученика' }
                                            </div>
                                            <div className={ classes.includesItem }>
                                                <div className={ classes.iconContainer }>
                                                    <img src={ includesSportIcon } alt="" />
                                                </div>
                                                { this.props.lang === 'ukr' ? 'робота з Учителем, який проходить щотижневі тренування' : 'работа с учителем, который проходит еженедельные тренировки' }
                                            </div>
                                            <div className={ classes.includesItem }>
                                                <div className={ classes.iconContainer }>
                                                    <img src={ includesStarIcon } alt="" />
                                                </div>
                                                { this.props.lang === 'ukr' ? 'урок, який готують для кожної групи окремо (спочатку його складає тічер, а потім перевіряє методист, бо ми любимо заморочуватись)' : 'урок, который готовится для каждой группы отдельно (сначала его готовит тичер, а потом проверяет методист, потому что мы любим заморачиваться)' }
                                            </div>
                                        </div>
                                    </div>
                            }
                    </VisibilitySensor>
                </div>
                <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isOnlineSectionVisible } onChange={ this.makeOnlineSectionVisible } >
                        {
                            ({ isVisible }) => 
                                <div className={ cn(classes.onlineOrOffline, {
                                    [classes.visible]: isVisible
                                }) }>
                                    <img src={onlineOrOfflineBackground} alt="" className={ classes.background } />
                                    <div className={ classes.wrapper }>
                                        <div className={ classes.info }>
                                            <div className={ classes.title }>
                                                { this.props.onlineTitle }
                                            </div>
                                            <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.props.onlineDescription } } />
                                        </div>
                                        <img src={ onlineOrOfflinePortrait } alt="" className={ classes.portrait } />
                                    </div>
                                </div>
                        }
                </VisibilitySensor>
            </>
        )
    }
}


export default Individual;