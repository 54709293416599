import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import * as actions from '../../../store/actions';
import classes from './Post.module.scss';
import axios from 'axios';
import Category from '../Category/Category';
import Button from '../../Button/Button';
import PostLink from '../PostLink/PostLink';
import CallToAction from '../../CallToAction/CallToAction';
import ReactGA from 'react-ga';

const svgPath = (
    <svg className={ classes.clipSvg } width="0" height="0" viewBox="0 0 770 521" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="postClipPath">
            <path d="M67.6318 494C129.277 522.125 315.671 520 406.446 520C497.22 520 654.276 525.949 708.47 492.667C762.664 459.386 770.979 363.834 768.946 254.749C766.914 145.663 757.244 69.617 708.47 38.2105C659.696 6.80396 451.427 0 316.62 0C181.814 0 129.277 8.05408 67.6316 40.8668C5.98658 73.6795 5.14794 221.953 1.08342 316.039C-2.98109 410.124 5.98665 465.875 67.6318 494Z" />
        </clipPath>
    </svg>
)

const authorSvgPath = (
    <svg className={ classes.clipSvg } width="0" height="0" viewBox="0 0 120 116" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="authorClipPath">
            <path d="M119.219 49.9286C119.219 94.1769 102.784 116 60.9361 116C28.5795 116 0.21875 91.8635 0.21875 59.8618C0.21875 22.0852 7.97962 -1.84501 60.9361 0.11157C112.371 0.11157 119.219 17.9269 119.219 49.9286Z" />
        </clipPath>
    </svg>
)

const svgPathMobile = (
    <svg className={ classes.clipSvg } width="296" height="200" viewBox="0 0 296 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="postClipPath">
            <path d="M25.897 189.849C49.5879 200.658 121.221 199.841 156.107 199.841C190.992 199.841 251.351 202.128 272.178 189.337C293.005 176.547 296.201 139.825 295.42 97.9026C294.639 55.9799 290.922 26.7545 272.178 14.6847C253.433 2.61482 173.394 0 121.586 0C69.7783 0 49.5878 3.09526 25.897 15.7055C2.20613 28.3158 1.88383 85.299 0.321793 121.457C-1.24024 157.615 2.20616 179.041 25.897 189.849Z" />
        </clipPath>
    </svg>
)

class PostPreview extends Component {

    state = {
        post: null,
        popularPosts: []
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        let lastURLSegment = this.props.match.params.post

        axios.get('https://api.engmonsters.in.ua/blog/preview/', {
            params: {
                lang: this.props.lang,
                url: lastURLSegment
            }
        })
        .then(response => {
            this.setState({
                post: response.data
            })
        })

        axios.get('https://api.engmonsters.in.ua/blog/all/', {
            params: {
                lang: this.props.lang,
                p: 1
            }
        })
        .then(response => {
            this.setState({
                popularPosts: response.data.posts.slice(0, 3),
            })
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.post !== prevProps.match.params.post || this.props.lang !== prevProps.lang) {
            if(this.props.match.params.post !== prevProps.match.params.post) {
                window.scrollTo(0, 0) 
            }
            let lastURLSegment = this.props.match.params.post

            axios.get('https://api.engmonsters.in.ua/blog/preview/', {
                params: {
                    lang: this.props.lang,
                    url: lastURLSegment
                }
            })
            .then(response => {
                this.setState({
                    post: response.data
                })
            })

            axios.get('https://api.engmonsters.in.ua/blog/all/', {
                params: {
                    lang: this.props.lang,
                    p: 1
                }
            })
            .then(response => {
                this.setState({
                    popularPosts: response.data.posts.slice(0, 3),
                })
            })
        }
    }

    showModal = () => {
        this.props.showModal()
    }

    render() {

        const posts = this.state.popularPosts.map(post => (
            <PostLink 
                key={ post.url }
                url={ post.url }
                photo={ post.pic }
                title={ post.header }
                text={ post.description }
            /> 
        ))

        const clipSvg = window.innerWidth < 800 ? svgPathMobile : svgPath;

        return (
            <>
                <div className={ classes.post }>
                    <Helmet>
                        <title>{ this.state.post && this.state.post.seo_title }</title>
                        <meta name="description" content={ this.state.post && this.state.post.seo_description } />
                    </Helmet>
                    <div className={ classes.wrapper }>
                        <div className={ classes.photo }>
                            { clipSvg }
                            <img src={ this.state.post && this.state.post.pic } alt=""  />
                        </div>
                        <div className={ classes.title }>
                            { this.state.post && this.state.post.header }
                        </div>
                        <div className={ classes.categoryContainer }>
                            <Category
                                active
                                url={ this.state.post && this.state.post.cats.length && this.state.post.cats[0].url } 
                                name={ this.state.post && this.state.post.cats.length && this.state.post.cats[0].name }
                            />
                            <div className={ classes.date }>
                                { this.state.post && this.state.post.date }
                            </div>
                        </div>
                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.post && this.state.post.content } }/>
                        <div className={ classes.authorContainer }>
                            <div className={ classes.author }>
                                { authorSvgPath }
                                    <img src={ this.state.post && this.state.post.author.pic } alt="" />
                            </div>
                            <div className={ classes.authorName }>
                                { this.state.post && this.state.post.author.name }
                            </div>
                            <div className={ classes.socialButtons }>
                                <Button
                                    shareButton
                                    facebook
                                    inFooter
                                    right
                                />
                                <Button
                                    shareButton
                                    telegram
                                    inFooter
                                    right
                                />
                            </div>
                            <div className={ classes.social }>
                                { this.props.lang === 'ukr' ? 'Поширити:' : 'Поделиться'}
                            </div>
                        </div>
                    </div>
                    <div className={ classes.shareMobile }>
                        <div className={ classes.socialMobile }>
                            { this.props.lang === 'ukr' ? 'Поширити:' : 'Поделиться'}
                        </div>
                        <div className={ classes.socialButtonsMobile }>
                                <Button
                                    shareButton
                                    facebook
                                />
                                <Button
                                    shareButton
                                    telegram
                                />
                        </div>
                    </div>
                    <div className={ classes.popularTitle }>
                        { this.props.lang === 'ukr' ? 'Популярні статті' : 'Популярные статьи'}
                    </div>
                    <div className={ classes.popularPosts }>
                        { posts }
                    </div>
                </div>
                <CallToAction 
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostPreview);