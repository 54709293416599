import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './MenuItem.module.scss';
import cn from 'classnames';

const MenuItem = (props) => {
    return(
        <NavLink to={ `/${ props.link }` } className={ classes.item } activeClassName={ classes.active }>
            <img 
                src={ props.img } 
                alt="" 
                className={ cn(classes.img, {
                    [classes.monstersLink]: props.monstersLink
                }) }
            />
            <div className={ classes.text }>
                { props.children }
            </div>
        </NavLink>
    )
}

export default MenuItem;