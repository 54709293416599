import React, { Component } from 'react';
import cn from 'classnames';
import classes from './Search.module.scss';
import closeIcon from '../../../assets/img/close-icon.svg';
import searchIcon from '../../../assets/img/search.svg';
import searchBorder from '../../../assets/img/search-border.png';


class Search extends Component {

    state = {
        isOpened: false
    }

    handleSearchStart = () => {
        this.setState({
            isOpened: true
        })
    }

    handleSearchBlur = () => {
        if(this.props.value === '') {
            this.setState({
                isOpened: false
            })
        }
    }

    handleSearchClose = () => {
        this.props.clearInput()
        this.setState({
            isOpened: false
        })
    }

    render() {
        return (
            <div className={ cn(classes.search, {
                [classes.opened]: this.state.isOpened
            }) }>
                <img src={ searchBorder } alt="" className={ classes.border } />
                <img src={ searchIcon } alt="" className={ classes.searchIcon } />
                <img src={ closeIcon } alt="" className={ classes.close } onClick={ this.handleSearchClose } />
                <form onSubmit={ (event) => this.props.submited(event) }>
                    <input 
                        type="text" 
                        className={ classes.input }
                        value={ this.props.value } 
                        placeholder={ this.props.lang === 'ukr' ? 'Шукати' : 'Искать' } 
                        onChange={ (event) => this.props.changed(event) }
                        onFocus={ this.handleSearchStart }
                        onBlur={ this.handleSearchBlur }
                    />
                </form>
            </div>
        )
    }
}

export default Search;