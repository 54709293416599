import React, { Component }  from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from './Reviews.module.scss';
import cloudTop from '../../../assets/img/reviews-cloud-top.svg';
import cloudBottom from '../../../assets/img/reviews-cloud-bottom.svg';

const NextArrow = (props) => <div onClick={ props.onClick } className={ classes.nextArrow }>&#62;</div>
const PrevArrow = (props) => <div onClick={ props.onClick } className={ classes.prevArrow }>&#60;</div>

class Reviews extends Component{

    componentDidUpdate() {
        this.slider.slickGoTo(0);
    }

    render() {

        const settings = {
            adaptiveHeight: true,
            dots: true,
            infinite: true,
            arrows: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />,
            dotsClass: 'reviews-slick-dots'
        }

        const slides = this.props.reviews.map((slide, index) => (
            <div className={ classes.slideOuter } key={ index }>
                <div className={ classes.slide }>
                    <div className={ classes.cloud }>
                        <img src={ cloudTop } alt="" className={ classes.cloudTop } />
                        <div className={ classes.cloudCenter } />
                        <img src={ cloudBottom } alt="" className={ classes.cloudBottom } />
                        <div className={ classes.cloudText } dangerouslySetInnerHTML={ { __html: slide.text } } />
                    </div>
                    <img src={ slide.pic } alt="" className={ classes.photo } />
                </div>
            </div>
        ))

        return(
            <div className={ classes.reviews }>
                <Slider ref={ slider => this.slider = slider } { ...settings }>
                    { slides }
                </Slider>
            </div>
        )
    }
}

export default Reviews;