import * as actionTypes from './actionTypes';

export const switchLang = (selectedLang) => {
    localStorage.setItem('lang', selectedLang)
    return {
        type: actionTypes.SWITCH_LANG,
        selectedLang: selectedLang
    }
}

export const switchType = (selectedType) => {
    localStorage.setItem('type', selectedType)
    return {
        type: actionTypes.TYPE_SWITCH,
        selectedType: selectedType
    }
}

export const showModal = () => {
    return {
        type: actionTypes.SHOW_MODAL
    }
}

export const hideModal = () => {
    return {
        type: actionTypes.HIDE_MODAL
    }
}

export const showCorporateModal = () => {
    return {
        type: actionTypes.SHOW_CORPORATE_MODAL
    }
}

export const showShopModal = (id) => {
    return {
        type: actionTypes.SHOW_PRODUCT_MODAL,
        id: id
    }
}