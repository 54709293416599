import React, { Component } from 'react';
import cn from 'classnames';
import classes from './Modal.module.scss';
import modal from '../../assets/img/modal.svg';
import modalSuccess from '../../assets/img/modal-success.svg'
import closeIcon from '../../assets/img/close-icon.svg';
import phoneIcon from '../../assets/img/phone-modal-icon.svg';
import Button from '../Button/Button';
import Input from '../Input/Input';
import axios from 'axios'
import { connect } from 'react-redux';

class Modal extends Component {
    
    state = {
        isSuccess: false,
        phoneNumber: '',
        name: '',
        usualModal: {},
        bossModal: {},
        shopModal: {}
    }

    componentDidMount() {
        axios.get('https://api.engmonsters.in.ua/modals/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            this.setState({
                usualModal: response.data.usual,
                bossModal: response.data.boss,
                shopModal: response.data.shop   
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lang !== this.props.lang) {
            axios.get('https://api.engmonsters.in.ua/modals/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    usualModal: response.data.usual,
                    bossModal: response.data.boss,
                    shopModal: response.data.shop   
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    handleInputChange = (event) => {
        this.setState({
            phoneNumber: event.target.value
        })
    }

    handleNameChange = (event) => {
        this.setState({
            name: event.target.value
        })
    }

    handleFormSubmit = () => {
        const formData = new FormData()
        formData.append('tel', this.state.phoneNumber)
        formData.append('url', window.location.href)
        if(this.props.modalType === 'shop') {
            formData.append('product_id', this.props.productId)
            formData.append('name', this.state.name)
        }
        if(this.props.modalType === 'solo') {
            formData.append('name', this.state.name)
        }
        if(this.props.modalType === 'corporate') {
            formData.append('company_name', this.state.name)
        }
                
        axios.post('https://api.engmonsters.in.ua/order/', formData)
            .then(() => {
                this.setState({
                    isSuccess: true,
                    name: '',
                    phoneNumber: ''
                })  
            })
            .catch(error => {
                console.log(error)
            })
    }

    handleCloseModal = () => {
        this.props.closeModal()
        if(this.state.isSuccess) {
            setTimeout(() => {
                this.setState({
                    isSuccess: false    
                })
            }, 400)
        }
    }

    getTitle = () => {
        if(this.props.modalType === 'solo') {
            return this.state.usualModal?.header
        }
        if(this.props.modalType === 'corporate') {
            return this.state.bossModal?.header
        }
        if(this.props.modalType === 'shop') {
            return this.state.shopModal?.header
        }
    }

    getText = () => {
        if(this.props.modalType === 'solo') {
            return this.state.usualModal?.text
        }
        if(this.props.modalType === 'corporate') {
            return this.state.bossModal?.text
        }
        if(this.props.modalType === 'shop') {
            return this.state.shopModal?.text
        }
    }

    getNamePlaceholder = () => {
        if(this.props.modalType === 'solo' || this.props.modalType === 'shop') {
            return this.props.lang === 'ukr' ? 'Твоє ім\'я' : 'Твое имя'
        }
        if(this.props.modalType === 'corporate') {
            return this.props.lang === 'ukr' ? 'Назва команії' : 'Название компании'
        }
    }

    render() {
        let content = this.state.isSuccess ? (
            <div className={ cn(classes.content, classes.success) }>
                <img src={ phoneIcon } alt=""  className={ classes.phoneIcon }/>
                <div className={ classes.title }>
                    Done!
                </div>
                <div className={ classes.text }>
                    { this.props.lang === 'ukr' ? 'Тепер чекай дзвіночка від Монстрів 😉' : 'Жди звоночка от Монстров 😉' }
                </div>
            </div>
        ) : (
            <div className={ classes.content }>
                <div className={ classes.title }>
                    { this.getTitle() }
                </div>
                <div className={ classes.text }>
                    { this.getText() }
                </div>
                <form 
                    className={ classes.form }
                    onSubmit={ this.handleFormSubmit }
                >
                    <Input 
                        value={ this.state.name }
                        placeholder={ this.getNamePlaceholder() }
                        onInputChange={ (event) => this.handleNameChange(event) }
                    />
                    <Input 
                        withMask
                        value={ this.state.phoneNumber }
                        placeholder={ this.props.lang === 'ukr' ? 'Твій телефон' : 'Твой телефон' }
                        onInputChange={ (event) => this.handleInputChange(event) }
                    />
                    <Button
                        right={ window.innerWidth > 650 ? true : false }
                        center={ window.innerWidth < 650 ? true : false }
                        callButton
                        clicked={ this.handleFormSubmit }
                    />
                </form>
            </div>
        )
        

        return (
            <>
                <div 
                    className={ cn(classes.overlay, {
                        [classes.visible]: this.props.isModalVisible
                    }) } 
                    onClick={ this.handleCloseModal }
                />
                <div className={ cn(classes.modal, {
                    [classes.modalSuccess]: this.state.isSuccess,
                    [classes.visible]: this.props.isModalVisible
                }) }>
                    <img src={ this.state.isSuccess ? modalSuccess : modal } alt="" className={ classes.modalShape }/>
                    <img 
                        src={ closeIcon } 
                        alt="" 
                        className={ classes.closeIcon }
                        onClick={ this.handleCloseModal }
                    />
                    { content }
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    productId: state.basic.productId
})

export default connect(mapStateToProps)(Modal);