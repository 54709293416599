import React from 'react';
import classes from './AgendaItem.module.scss';
import background from '../../../assets/img/agenda.png';
import backgroundMobile from '../../../assets/img/agenda-mobile.png';
import { Link } from 'react-router-dom';
import cn from 'classnames';

// assets

import bottleHalf from '../../../assets/img/bottle-half.svg';
import bottleHalfDisabled from '../../../assets/img/bottle-half-disabled.svg';
import bottleEmpty from '../../../assets/img/bottle-empty.svg';
import bottleEmptyDisabled from '../../../assets/img/bottle-empty-disabled.svg';
import bottleFull from '../../../assets/img/bottle-full.svg';
import bottleFullDisabled from '../../../assets/img/bottle-full-disabled.svg';
import calendarYellowIcon from '../../../assets/img/calendar-icon-yellow.svg';
import calendarDisabledIcon from '../../../assets/img/calendar-icon-disabled.svg';
import clockYellowIcon from '../../../assets/img/clock-yellow.svg';
import clockDisabledIcon from '../../../assets/img/clock-disabled.svg';
import personYellowIcon from '../../../assets/img/person-yellow.svg';
import personDisabledIcon from '../../../assets/img/person-disabled.svg';
import soldOut from '../../../assets/img/sold-out.svg';

const AgendaItem = (props) => {

    let bottles;
    let titlePrefix
    const backgroundImg = window.innerWidth <= 1100 ? backgroundMobile : background;
    const appliedPeople = props.people.substr(0, props.people.indexOf('/')); 
    const totalPeople = props.people.substr(props.people.indexOf('/') + 1); 
    const isSoldOut = totalPeople === appliedPeople

    console.log(isSoldOut);

    if(props.index === 0) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleHalfDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleHalf } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 1) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 2) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleHalfDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleHalf } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 3) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 4) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleHalfDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleHalf } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 5) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleEmptyDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleEmpty } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 6) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleHalfDisabled } alt="" /> 
                </div>
            )
                
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleHalf } alt="" /> 
                </div>
            )
        }
    } else if(props.index === 7) {
        if(isSoldOut) {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                    <img src={ bottleFullDisabled } alt="" /> 
                </div>
            )    
        } else {
            bottles = (
                <div className={ classes.bottles }>
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                    <img src={ bottleFull } alt="" /> 
                </div>
            )
        }
    }

    if(totalPeople - appliedPeople === 1) {
        titlePrefix = (
            <>
                <span role="img" aria-label="fire">&#128293;</span>
                <span role="img" aria-label="fire">&#128293;</span>
            </>
        )
    } else if( totalPeople - appliedPeople === 2 || totalPeople - appliedPeople === 3 ) {
        titlePrefix = (
            <span role="img" aria-label="fire">&#128293;</span>
        )
    }

    return props.url ? (
        <Link 
            to={`/group/${ props.url }`}
            className={ cn(classes.agendaItem , {
                [classes.soldOut]: isSoldOut
            }) }
        >
            { isSoldOut && <img src={ soldOut } alt="" className={ classes.soldOutIcon } /> }
            <img src={ backgroundImg } alt="" className={ classes.background } />
            <div className={ classes.content }>
                { bottles }
                <div className={ classes.title }>
                    { titlePrefix ? <span>{ titlePrefix } { props.title }</span> : props.title }
                </div>
                { props.date && (
                    <div className={ classes.option }>
                        <img 
                            src={ isSoldOut ? calendarDisabledIcon : calendarYellowIcon } 
                            alt="" 
                            className={ classes.icon }
                        />
                        <span>{ props.date }</span>
                    </div>
                ) }
                { props.people && (
                    <div className={ classes.option }>
                        <img 
                            src={ isSoldOut ? personDisabledIcon : personYellowIcon } 
                            alt="" 
                            className={ classes.icon }
                        />
                        <span>{ props.people }</span>
                    </div>
                ) }
                { props.agenda && (
                    <div className={ classes.option }>
                        <img 
                            src={ isSoldOut ? clockDisabledIcon : clockYellowIcon } 
                            alt="" 
                            className={ classes.icon }
                        />
                        <span>{ props.agenda }</span>
                    </div>
                ) }
            </div>
        </Link>
    ) : (
        <div 
            className={ cn(classes.agendaItem , {
                [classes.soldOut]: isSoldOut
            }) }
        >
            { isSoldOut && <img src={ soldOut } alt="" className={ classes.soldOutIcon } /> }
            <img src={ backgroundImg } alt="" className={ classes.background } />
            <div className={ classes.content }>
                { bottles }
                <div className={ classes.title }>
                    { titlePrefix ? <span>{ titlePrefix } { props.title }</span> : props.title }
                </div>
                { props.date && (
                    <div className={ classes.option }>
                        <img 
                            src={ isSoldOut ? calendarDisabledIcon : calendarYellowIcon } 
                            alt="" 
                            className={ classes.icon }
                        />
                        <span>{ props.date }</span>
                    </div>
                ) }
                { props.people && (
                    <div className={ classes.option }>
                        <img 
                            src={ isSoldOut ? personDisabledIcon : personYellowIcon } 
                            alt="" 
                            className={ classes.icon }
                        />
                        <span>{ props.people }</span>
                    </div>
                ) }
                { props.agenda && (
                    <div className={ classes.option }>
                        <img 
                            src={ isSoldOut ? clockDisabledIcon : clockYellowIcon } 
                            alt="" 
                            className={ classes.icon }
                        />
                        <span>{ props.agenda }</span>
                    </div>
                ) }
            </div>
        </div>
    )
}

export default AgendaItem;