import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Monsters.module.scss';
import * as actions from '../../store/actions';
import axios from 'axios';
import Title from '../Title/Title';
import MonstersSlider from './MonstersSlider/MonstersSlider';
import CallToAction from '../CallToAction/CallToAction';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';
import ReactGA from 'react-ga';

// assets

import goodTeacher from '../../assets/img/good-teacher.png'
import goodTeacherLabel from '../../assets/img/good-teacher-label.svg'
import x from '../../assets/img/good-teacher-x.svg'
import betterTeacher from '../../assets/img/better-teacher.png'
import betterTeacherLabel from '../../assets/img/better-teacher-label.svg'
import betterTeacherCrown from '../../assets/img/better-teacher-crown.svg'
import factsBg from '../../assets/img/facts-background.png'

class Monsters extends Component {

    state = {
        pageContent: [],
        facts: [],
        monsters: [],
        isFactsInvisible: true,
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);

        axios.get('https://api.engmonsters.in.ua/monsters_page/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            this.setState({
                pageContent: response.data.texts,
                facts: response.data.facts,
                monsters: response.data.monsters,
                seo: response.data.seo
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.lang !== prevProps.lang) {
            axios.get('https://api.engmonsters.in.ua/monsters_page/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    pageContent: response.data.texts,
                    facts: response.data.facts,
                    monsters: response.data.monsters,
                    seo: response.data.seo
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    showModal = () => {
        this.props.showModal()
    }

    makeFactsVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isFactsInvisible: false
            })
        }
    }

    render() {

        const facts = this.state.facts && this.state.facts.map((fact, index) => (
            <div className={ classes.factItem } key={ index }>
                <div className={ classes.factIconContainer }>
                    <img src={ fact.pic } alt="" className={ classes.factIcon } />
                </div>
                <div className={ classes.factText } dangerouslySetInnerHTML={ { __html: fact.desc } } />
            </div>
        ))

        return (
            <div className={ classes.monsters }>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <div className={ classes.hero }>
                    <Title center>{ this.state.pageContent.header }</Title>
                    <div className={ classes.subtitle } dangerouslySetInnerHTML={ { __html: this.state.pageContent.header_desc } } />
                    <div className={ classes.image }>
                        <img src={ goodTeacher } alt="" className={ classes.goodTeacher } />
                        <img src={ goodTeacherLabel } alt="" className={ classes.goodTeacherLabel } />
                        <div className={ classes.goodTeacherLabelText }>
                            { this.props.lang === 'ukr' ? 'крутий вчитель' : 'крутой учитель' }
                        </div>
                        <div className={ classes.teacherAdvantages }>
                            <div className={ classes.item }>
                                <img src={ x } alt="" className={ classes.x } />
                                { this.props.lang === 'ukr' ? 'Спільні тренування' : 'Общие тренировки' } 
                            </div>
                            <div className={ classes.item }>
                                <img src={ x } alt="" className={ classes.x } />
                                { this.props.lang === 'ukr' ? 'Моральна підтримка' : 'Моральная поддержка' } 
                            </div>
                            <div className={ classes.item }>
                                <img src={ x } alt="" className={ classes.x } />
                                { this.props.lang === 'ukr' ? 'Обмін досвідом' : 'Обмен опытом' } 
                            </div>
                        </div>
                        <div className={ classes.betterTeacherEqual }>=</div>
                        <img src={ betterTeacher } alt="" className={ classes.betterTeacher } />
                        <img src={ betterTeacherLabel } alt="" className={ classes.betterTeacherLabel } />
                        <div className={ classes.betterTeacherLabelText }>
                            { this.props.lang === 'ukr' ? 'афігєзний вчитель' : (
                                <span className={ classes.rus }>
                                    Афигительный учитель
                                </span>
                            ) }
                        </div>
                        <img src={ betterTeacherCrown } alt="" className={ classes.betterTeacherCrown } />
                        <div className={ classes.betterTeacherDouble }>2</div>
                    </div>
                </div>
                <div className={ classes.facts }>
                    <img src={ factsBg } alt="" className={ classes.background } />
                    <Title center color={ '#fff' }>{ this.state.pageContent.facts_header }</Title>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isFactsInvisible } onChange={ this.makeFactsVisible }>
                        {   
                            ({ isVisible }) =>
                            <div className={ cn(classes.factsWrapper, {
                                [classes.visible]: isVisible
                            }) }>
                                { facts }
                            </div>
                        }
                    </VisibilitySensor>
                </div>
                <div>
                    <Title center>{ this.state.pageContent.monsters_header }</Title>
                    <MonstersSlider monsters={ this.state.monsters } />
                </div>
                <CallToAction 
                    title={ this.state.pageContent.call_to_action_header }
                    subtitle={ this.state.pageContent.call_to_action_desc }
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Monsters);