import React from 'react';
import { Link } from 'react-router-dom';
import classes from './EventLink.module.scss';

// assets

import shadow from '../../../assets/img/event-shadow.png';
import shadowSmall from '../../../assets/img/event-shadow-small.png';
import shadowMobile from '../../../assets/img/event-shadow-mobile.png';
import methodsMonster from '../../../assets/img/methods-monster.svg';
import calendarMonster from '../../../assets/img/calendar-monster.svg';
import monstersMonster from '../../../assets/img/monsters-monster.svg';

import dollarGreenIcon from '../../../assets/img/dollar-green.svg';
import clockGreenIcon from '../../../assets/img/clock-green.svg';
import pinGreenIcon from '../../../assets/img/pin-green.svg';
import calendarGreenIcon from '../../../assets/img/calendar-icon-green.svg'

const eventContainerSvgPath = (
    <svg className={ classes.svgClip } width="1232" height="323" viewBox="0 0 1232 323" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="eventContainerSvgPath">
            <path d="M91.8746 287.432C187.696 300.596 511.005 303 652.104 303C793.204 303 1037.33 301.104 1121.57 285.527C1205.81 269.951 1218.74 182.284 1215.58 131.229C1212.42 80.174 1197.39 44.5825 1121.57 29.8835C1045.76 15.1844 722.023 12 512.479 12C302.935 12 221.272 20.4497 125.45 35.8069C29.629 51.1641 28.3254 115.88 22.0075 159.914C15.6896 203.948 -3.94683 274.269 91.8746 287.432Z"/>
        </clipPath>
    </svg>
)

const eventContainerSvgPathSmall = (
    <svg className={ classes.svgClip } width="1032" height="323" viewBox="0 0 1032 323" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="eventContainerSvgPathSmall">
            <path d="M79.2289 287.432C159.08 300.596 428.504 303 546.087 303C663.67 303 867.111 301.104 937.31 285.527C1007.51 269.951 1018.28 182.284 1015.65 131.229C1013.02 80.174 1000.49 44.5825 937.31 29.8835C874.131 15.1844 604.353 12 429.733 12C255.113 12 187.06 20.4498 107.209 35.8069C27.3575 51.1641 26.2712 115.88 21.0062 159.914C15.7413 203.948 -0.622354 274.269 79.2289 287.432Z" />
        </clipPath>
    </svg>    
)

const eventContainerSvgPathMobile = (  
    <svg className={ classes.svgClip } width="320" height="668" viewBox="0 0 320 668" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="eventContainerSvgPathMobile">
            <path d="M27.7508 623.342C51.5984 646.438 133.706 650.656 168.822 650.656C203.938 650.656 264.696 647.329 285.661 620C306.626 592.671 309.843 403.247 309.057 313.671C308.271 224.095 304.53 68.1663 285.661 42.3767C266.793 16.5871 186.223 11 134.073 11C81.9225 11 61.5984 25.8252 37.7508 52.7695C13.9032 79.7137 13.5788 286.741 12.0064 364C10.4341 441.259 3.90321 600.247 27.7508 623.342Z" />
        </clipPath>
    </svg>
    
)

const eventPhotoSvgPath = (
    <svg className={ classes.svgClip } width="388" height="291" viewBox="0 0 388 291" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="eventPhotoSvgPath">
            <path d="M-17 1.5L351 -9C351 -9 389.5 1.5 387.5 142.5C385.5 283.5 354.5 300.5 354.5 300.5L-17 292.5V1.5Z"/>
        </clipPath>
    </svg>
)

const EventLink = (props) => {

    let shadowImg = shadow;
    let svgPath = eventContainerSvgPath;

    if(window.innerWidth <= 1100) {
        svgPath = eventContainerSvgPathMobile
        shadowImg = shadowMobile
    } else if(window.innerWidth <= 1300) {
        shadowImg = shadowSmall;
        svgPath = eventContainerSvgPathSmall
    }

    return (
        <Link to={ `/event/${ props.url }` } className={ classes.event }>
            {  window.innerWidth <= 1100 ? null : eventPhotoSvgPath }
            { svgPath }
            <img className={ classes.shadow } alt="" src={ shadowImg } />
            <div className={ classes.eventWrapperOuter }>
                <div className={ classes.eventWrapper }>
                    <img src={ monstersMonster } alt="" className={ classes.firstMonster } />
                    <img src={ calendarMonster } alt="" className={ classes.secondMonster } />
                    <img src={ methodsMonster } alt="" className={ classes.thirdMonster } />
                    <div className={ classes.photo }>
                        <img src={ props.photo } alt="" />
                    </div>
                    <div className={ classes.title }>
                        { props.name }
                    </div>
                    <div className={ classes.description }>
                        { props.description }
                    </div>
                    <div className={ classes.optionsContainer }>
                        { props.date && (
                            <div className={ classes.option }>
                                <div className={ classes.icon }>
                                    <img src={ calendarGreenIcon } alt="" />
                                </div>
                                <span>
                                    { props.date }
                                </span>
                            </div>
                        ) }
                        { props.time && (
                            <div className={ classes.option }>
                                <div className={ classes.icon }>
                                    <img src={ clockGreenIcon } alt="" />
                                </div>
                                <span>
                                    { props.time }
                                </span>
                            </div>
                        ) }
                        { props.place && (
                            <div className={ classes.option }>
                                <div className={ classes.icon }>
                                    <img src={ pinGreenIcon } alt="" />
                                </div>
                                <span>
                                    { props.place }
                                </span>
                            </div>
                        ) }
                        { props.price && (
                            <div className={ classes.option }>
                                <div className={ classes.icon }>
                                    <img src={ dollarGreenIcon } alt="" />
                                </div>
                                <span>
                                    { props.price }
                                </span>
                            </div>
                        ) }
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default EventLink;

