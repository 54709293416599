import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import Header from '../Header/Header';
import Modal from '../Modal/Modal';
import MobileMenu from '../MobileMenu/MobileMenu';
import TopButton from '../TopButton/TopButton';
import classes from './Layout.module.scss';
import cn from 'classnames';

class Layout extends Component {

    state = {
        isMenuOpened: false,
        isTopButtonVisible: false
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll, false)
        const localLang = localStorage.getItem('lang');
        const localType = localStorage.getItem('type');

        if(localLang) {
            this.props.switchLang(localLang);
        } else {
            localStorage.setItem('lang', this.props.lang)
        }
        if(localType) {
            this.props.switchType(localType);
        } else {
            localStorage.setItem('type', this.props.type)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll, false)
    }

    handleScroll = () => {
        if(window.scrollY < 1000 && this.state.isTopButtonVisible) {
            this.setState({
                isTopButtonVisible: false
            })
        } else if(window.scrollY > 1000 && !this.state.isTopButtonVisible) {
            this.setState({
                isTopButtonVisible: true
            })
        }
    }

    openMenuHandler = () => {
        document.body.style.overflow = 'hidden';
        this.setState({
            isMenuOpened: true
        })
    }

    closeMenuHandler = () => {
        document.body.style.overflow = 'unset';
        this.setState({
            isMenuOpened: false
        })
    }

    gotoTopHandler = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <>
                <Modal 
                    isModalForBoss={ this.props.modalType }
                    isModalVisible={ this.props.isModalVisible } 
                    closeModal={ this.props.hideModal }
                    modalType={ this.props.modalType }
                    lang={ this.props.lang } 
                />
                <Header
                    isMenuOpened={ this.state.isMenuOpened }
                    menuButtonClicked={ this.openMenuHandler }
                    closeMenuButtonClicked={ this.closeMenuHandler }
                    lang={ this.props.lang }
                    showModal={ this.props.showModal }
                />
                <MobileMenu 
                    isMenuOpened={ this.state.isMenuOpened }
                    closeMenuButtonClicked={ this.closeMenuHandler }
                    lang={ this.props.lang } 
                    showModal={ this.props.showModal }
                />
                <div className={ cn(classes.topButton, { [classes.visible]: this.state.isTopButtonVisible }) } ref={ topButtonRef => this.topButtonRef = topButtonRef }>
                    <TopButton clicked={ this.gotoTopHandler } />
                </div>
                {this.props.children}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang,
        type: state.basic.type
    }
}

const mapDispatchToProps = dispatch => {
    return{
        switchLang: (lang) => dispatch(actions.switchLang(lang)),
        switchType: (type) => dispatch(actions.switchType(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);

