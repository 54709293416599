import React from 'react';
import classes from './Hero.module.scss';
import Button from '../../Button/Button';
import portrait from '../../../assets/img/hero-portrait.png';
import cloud from '../../../assets/img/hero-portrait-cloud.svg';
import cn from 'classnames';

const Hero = (props) => {
    return(
        <div className={ classes.hero }>
            <div className={ classes.info }>
                <h1>{ props.title }</h1>
                <div className={ classes.description } dangerouslySetInnerHTML={ { __html: props.description } } />
                <div className={ classes.buttons }>
                    <Button messangerButton />
                    <Button callButton clicked={ props.showModal } />
                </div>
            </div>
            <div className={ classes.image }>
                <img src={ cloud } alt="" className={ classes.cloud } />
                <img src={ portrait } alt="" className={ classes.portrait } />
                <div className={ cn(classes.cloudText, {
                    [classes.rus]: props.lang === 'rus'
                }) }>
                    { props.lang === 'ukr' ? 'Нарешті вивчив герундій!' : 'Наконец-то выучил герундий!' }
                </div>
            </div>
        </div>
    )
}

export default Hero;