import React from 'react';
import { Link } from 'react-router-dom';
import classes from './PostLink.module.scss';
import postShadow from '../../../assets/img/post-shadow.png'
import postShadowMobile from '../../../assets/img/post-shadow-mobile.png'


const svgPath = window.innerWidth > 1300 ? (
    <svg className={ classes.svg } width="0" height="0" viewBox="0 0 0 0" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="clipPath">
            <path d="M39.1967 490.418C67.7357 518.056 164.028 523.104 206.053 523.104C248.077 523.104 320.787 519.123 345.876 486.418C370.966 453.712 374.815 369.641 373.874 262.444C372.933 155.246 368.457 80.5161 345.876 49.6531C323.296 18.7902 226.877 12.104 164.467 12.104C102.058 12.104 77.7356 29.8456 49.1967 62.0904C20.6578 94.3352 20.2696 230.216 18.3879 322.673C16.5062 415.13 10.6578 462.779 39.1967 490.418Z" />
        </clipPath>
    </svg>
) : (
    <svg className={ classes.svg } width="298" height="427" viewBox="0 0 298 427" fill="none" xmlns="http://www.w3.org/2000/svg">
        <clipPath id="clipPath">
            <path d="M26.0683 399.687C49.9159 422.782 122.023 427 157.14 427C192.256 427 253.014 423.673 273.979 396.344C294.944 369.015 298.16 298.764 297.374 209.188C296.588 119.612 292.847 57.1663 273.979 31.3767C255.11 5.58707 174.541 0 122.39 0C70.24 0 49.9159 14.8252 26.0683 41.7695C2.22073 68.7137 1.8963 182.258 0.323925 259.516C-1.24845 336.775 2.2207 376.592 26.0683 399.687Z" />
        </clipPath>
    </svg>
)

const PostLink = (props) => (
    <Link to={ `/post/${ props.url }` } className={ classes.post }>
        { svgPath }
        <img src={ window.innerWidth > 1300 ? postShadow : postShadowMobile } className={ classes.shadow } alt=""/>
        <div className={ classes.postWrapper }>
            <div className={ classes.postInnerContainer }>
                <div className={ classes.postInner }>
                    <img src={ props.photo }  alt="" className={ classes.photo }/>
                    <div className={ classes.info }>
                        <div className={ classes.title }>{ props.title }</div>
                        <div className={ classes.text }>{ props.text }</div>
                    </div>
                </div>
            </div>
        </div>
    </Link>
)

export default PostLink