import React, { Component } from 'react';
import classes from './CallToAction.module.scss';
import Title from '../Title/Title';
import background from '../../assets/img/call-to-action-bg.png'
import Button from '../Button/Button';
import Footer from '../Footer/Footer';
import cn from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

class CallToAction extends Component {

    state = {
        isInvisible: true,
    }

    makeVisible = (isVisible) => {
        if(isVisible) {
            this.setState({
                isInvisible: false
            })
        }
    }

    getTitle = () => {
        if (this.props.title) {
            return this.props.title
        }
        if (this.props.lang === 'ukr') {
            return 'Досить думати!'
        }
        return 'Хватит думать!'
    }
    
    getSubtitle = () => {
        if (this.props.subtitle) {
            return this.props.subtitle
        }
        if (this.props.lang === 'ukr') {
            return 'Ти нарешті заговориш!'
        }
        return 'Ты наконец-то заговоришь!'
    }

    render() {
        return (
            <>
                <div className={ classes.container }>
                    <img src={ background } alt=""  className={ classes.background } />
                    <Title callToAction center color={ '#fff' }>
                        { this.getTitle() }
                    </Title>
                    <VisibilitySensor partialVisibility={ true } delayedCall={ true } active={ this.state.isInvisible } onChange={ this.makeTitleVisible } >
                        {
                            ({ isVisible }) =>
                            <div className={ cn({
                                [classes.visible]: isVisible
                            }) }>
                                <div className={ classes.subtitle }>
                                    { this.getSubtitle() }
                                </div>
                                <div className={ classes.buttons }>
                                    <Button whiteMessangerButton />
                                    <Button callButton clicked={ this.props.showModal } />
                                </div>
                            </div>
                        }
                    </VisibilitySensor>
                </div>
                <Footer 
                    lang={ this.props.lang }
                    inCallToAction
                />
            </>
        )
    }    
}


export default CallToAction;