import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import cn from 'classnames';
import classes from './LanguageSwitch.module.scss';

import arrowIcon from '../../assets/img/type-switch-arrow.svg';
import arrowFooterIcon from '../../assets/img/type-switch-arrow-footer.svg';
import dropdown from '../../assets/img/type-switch-dropdown.svg';
import separator from '../../assets/img/type-switch-line.svg'

class LanguageSwitch extends Component {

    state = {
        opened: false
    }

    switchLang = (lang) => {
        this.props.switchLang(lang)
    }

    render() {

        let label = this.props.lang === 'ukr' ? 'Українська' : 'Русский';
        let arrow = this.props.inFooter ? arrowFooterIcon : arrowIcon;
        
        return(
            <div className={ classes.container }>
                <div className={ cn(classes.control, {
                    [classes.white]: this.props.inFooter
                }) }>
                    { label }
                    <img src={ arrow } alt="" className={ classes.arrow } />
                </div>
                <div className={ classes.dropdown }>
                    <img src={ dropdown } alt="" className={ classes.dropdownBackground } />
                    <div className={ classes.dropdownInner }>
                        <div className={ classes.option } onClick={ () => this.switchLang('ukr') }>Українська</div>
                        <img src={ separator } alt="" />
                        <div className={ classes.option } onClick={ () => this.switchLang('rus') }>Русский</div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang,
    }
}
  
const mapDispatchToProps = dispatch => {
    return{
        switchLang: (lang) => dispatch(actions.switchLang(lang)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);