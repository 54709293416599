import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Service.module.scss';
import axios from 'axios';
import ReactGA from 'react-ga';
import cn from 'classnames';
import * as actions from '../../../store/actions';
import CallToAction from '../../CallToAction/CallToAction';

import dollarYellowIcon from '../../../assets/img/dollar-yellow.svg'
import dollarGreenIcon from '../../../assets/img/dollar-green.svg'
import dollarRedIcon from '../../../assets/img/dollar-red.svg'

import clockYellowIcon from '../../../assets/img/clock-yellow.svg'
import clockGreenIcon from '../../../assets/img/clock-green.svg'
import clockRedIcon from '../../../assets/img/clock-red.svg'

import pinRedIcon from '../../../assets/img/pin-red.svg'
import pinYellowIcon from '../../../assets/img/pin-yellow.svg'
import pinGreenIcon from '../../../assets/img/pin-green.svg'

import personYellowIcon from '../../../assets/img/person-yellow.svg';
import personRedIcon from '../../../assets/img/person-red.svg';
import personGreenIcon from '../../../assets/img/person-green.svg';

class Service extends Component {

    state = {
        service: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);

        if(this.props.match.params.serviceType === 'other') {
            axios.get('https://api.engmonsters.in.ua/service/', {
                params: {
                    lang: this.props.lang,
                    url: this.props.match.params.serviceUrl
                }
            })
            .then(response => {
                this.setState({
                    service: response.data[0],
                    type: 'other'
                })
            })
            .catch(error => {
                console.log(error);
            })     
        } else {
            axios.get('https://api.engmonsters.in.ua/service_main/', {
                params: {
                    lang: this.props.lang,
                    url: this.props.match.params.serviceUrl
                }
            })
            .then(response => {
                this.setState({
                    service: response.data[0],
                    type: this.props.match.params.serviceType
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.match.params.eventUrl !== prevProps.match.params.eventUrl || this.props.lang !== prevProps.lang) {
            if(this.props.match.params.eventUrl !== prevProps.match.params.eventUrl) {
                window.scrollTo(0, 0);
            }

            axios.get('https://api.engmonsters.in.ua/service/', {
                params: {
                    lang: this.props.lang,
                    url: this.props.match.params.eventUrl
                }
            })
            .then(response => {
                this.setState({
                    event: response.data[0]
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    render() {

        const onlineBadge = (
            <div className={ classes.badge }>
                <svg width="135" height="43" viewBox="0 0 135 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M135 24.3771C135 42.9158 102.754 43.3317 64.2513 42.9158C25.7487 42.4999 0 43.5841 0 21.7921C0 6.3953e-05 21.4978 0 74.393 0C121.742 0 135 2.48547 135 24.3771Z" fill="#F0582E"/>
                </svg>
                <div className={ classes.badgeText }>Online</div>
            </div>
        )
    
        const offlineBadge = (
            <div className={ classes.badge }>
                <svg width="136" height="43" viewBox="0 0 136 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M136 24.3771C136 42.9158 114.343 43.3317 75.5556 42.9158C36.7677 42.4999 0 43.5841 0 21.7921C0 6.41864e-05 6.21296 0 59.5 0C107.2 0 136 2.48547 136 24.3771Z" fill="#FDCF41"/>
                </svg>
                <div className={ classes.badgeText }>Offline</div>
            </div>
        )

        let dollarIcon
        let clockIcon
        let pinIcon
        let personIcon
        let options
        
        if (this.state.service.color === '#FDCF41') {
            dollarIcon = dollarYellowIcon
            clockIcon = clockYellowIcon
            pinIcon = pinYellowIcon
            personIcon = personYellowIcon
        } else if(this.state.service.color === '#F55428') {
            dollarIcon = dollarRedIcon
            clockIcon = clockRedIcon
            pinIcon = pinRedIcon
            personIcon = personRedIcon
        } else if(this.state.service.color === '#91D1C5'){
            dollarIcon = dollarGreenIcon
            clockIcon = clockGreenIcon
            pinIcon = pinGreenIcon
            personIcon = personGreenIcon
        }

        if (this.state.type === 'other') {
            options = (
                <div className={ classes.optionsContainer }>
                    { this.state.service.date && (
                        <div className={ classes.option }>
                            <img src={ personIcon } alt="" className={ classes.icon } />
                            <span style={ { color: this.state.service.color } }>
                                { this.state.service.date }
                            </span>
                        </div>
                    ) }
                    { this.state.service.time && (
                        <div className={ classes.option }>
                            <img src={ clockIcon } alt="" className={ classes.icon } />
                            <span style={ { color: this.state.service.color } }>
                                { this.state.service.time }
                            </span>
                        </div>
                    ) }
                    { this.state.service.place && (
                        <div className={ classes.option }>
                            <img src={ pinIcon } alt="" className={ cn(classes.icon, classes.pinIcon) } />
                            <span style={ { color: this.state.service.color } }>
                                { this.state.service.place }
                            </span>
                        </div>
                    ) }
                    { this.state.service.price && (
                        <div className={ classes.option }>
                            <img src={ dollarIcon } alt="" className={ cn(classes.icon, classes.priceIcon) } />
                            <span style={ { color: this.state.service.color } }>
                                { this.state.service.price }
                            </span>
                        </div>
                    ) }
                </div>
            )
        } else if (this.state.type === 'individual') {
            options = (
                <div className={ classes.details }>
                    <div className={ classes.online }>
                        { onlineBadge }
                        { this.state.service && this.state.service.online_price && (
                            <div className={ classes.price }>
                                <img src={ dollarRedIcon } alt="" />
                                { this.state.service.online_price }
                            </div>
                        ) }
                        { this.state.service && this.state.service.online_time && (
                            <div className={ classes.time }>
                                <img src={ clockRedIcon } alt="" />
                                { this.state.service.online_time }
                            </div>
                        ) }
                        { this.state.service && this.state.service.online_place && (
                            <div className={ classes.place }>
                                <img src={ pinRedIcon } alt="" />
                                { this.state.service.online_place }
                            </div>
                        ) }
                        { this.state.service && this.state.service.online_people && (
                            <div className={ classes.person }>
                                <img src={ personRedIcon } alt="" />
                                { this.state.service.online_people }
                            </div>
                        ) }
                    </div>
                    <div className={ classes.offline }>
                        { offlineBadge }
                        { this.state.service && this.state.service.offline_price && (
                            <div className={ classes.price }>
                                <img src={ dollarYellowIcon } alt="" />
                                { this.state.service.offline_price }
                            </div>
                        ) }
                        { this.state.service && this.state.service.offline_time && (
                            <div className={ classes.time }>
                                <img src={ clockYellowIcon } alt="" />
                                { this.state.service.offline_time }
                            </div>
                        ) }
                        { this.state.service && this.state.service.offline_place && (
                            <div className={ classes.place }>
                                <img src={ pinYellowIcon } alt="" />
                                { this.state.service.offline_place }
                            </div>
                        ) }
                        { this.state.service && this.state.service.offline_people && (
                            <div className={ classes.person }>
                                <img src={ personYellowIcon } alt="" />
                                { this.state.service.offline_people }
                            </div>
                        ) }
                    </div>
                </div>
            )
        } else if (this.state.type === 'corporate') {
            options = (
                <div className={ classes.corporateDetails }>
                    { this.state.service && this.state.service.offline_price && (
                        <div className={ classes.price }>
                            <img src={ dollarGreenIcon } alt="" />
                            { this.state.service.offline_price }
                        </div>
                    ) }
                    { this.state.service && this.state.service.offline_time && (
                        <div className={ classes.time }>
                            <img src={ clockGreenIcon } alt="" />
                            { this.state.service.offline_time }
                        </div>
                    ) }
                    { this.state.service && this.state.service.offline_place && (
                        <div className={ classes.place }>
                            <img src={ pinGreenIcon } alt="" />
                            { this.state.service.offline_place }
                        </div>
                    ) }
                    { this.state.service && this.state.service.offline_people && (
                        <div className={ classes.time }>
                            <img src={ personGreenIcon } alt="" />
                            { this.state.service.offline_people }
                        </div>
                    ) }
                </div>
            )
        }


        return(
            <>
                <div className={ classes.service }>
                    <Helmet>
                        <title>{ this.state.service.name }</title>
                    </Helmet>
                    <div className={ classes.wrapper }>
                        <div className={ classes.photo }>
                            <img src={ this.state.service.page_pic } alt="" />
                        </div>
                        <div className={ classes.title }>{ this.state.service.name }</div>
                        <div className={ classes.text } dangerouslySetInnerHTML={ { __html: this.state.service.content } } />
                        { options }
                    </div>
                </div>
                <CallToAction
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service);