import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Home.module.scss';
import * as actions from '../../store/actions';
import axios from 'axios';
import ReactGA from 'react-ga';

// modules

import Hero from './Hero/Hero';
import Title from '../Title/Title';
import HowWeWork from './HowWeWork/HowWeWork';
import WhatWeCan from './WhatWeCan/WhatWeCan';
import Contacts from './Contacts/Contacts';
import Reviews  from './Reviews/Reviews';
import CallToAction from '../CallToAction/CallToAction';


// assets

class Home extends Component {
    
    state = {
        pageContent: {},
        reviews: [],
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);

        axios.get('https://api.engmonsters.in.ua/main_page/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            this.setState({
                pageContent: response.data.texts,
                reviews: response.data.reviews,
                seo: response.data.seo
            })

        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(prevProps.lang !== this.props.lang){
            axios.get('https://api.engmonsters.in.ua/main_page/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    pageContent: response.data.texts,
                    seo: response.data.seo
                })

            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    handleNavigateToServices = () => {
        this.props.history.push('../services')
    }

    showModal = () => {
        this.props.showModal()
    }

    render() {

        const methods = {
            title1:  this.state.pageContent.method_1_header,
            desc1: this.state.pageContent.method_1_desc,
            title2:  this.state.pageContent.method_2_header,
            desc2: this.state.pageContent.method_2_desc,
            title3:  this.state.pageContent.method_3_header,
            desc3: this.state.pageContent.method_3_desc,
            title4:  this.state.pageContent.method_4_header,
            desc4: this.state.pageContent.method_4_desc,
            title5:  this.state.pageContent.method_5_header,
            desc5: this.state.pageContent.method_5_desc,
            title6:  this.state.pageContent.method_6_header,
            desc6: this.state.pageContent.method_6_desc
        }

        return(
            <div>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <Hero 
                    showModal={ this.showModal }
                    title={ this.state.pageContent.header }
                    description={ this.state.pageContent.header_desc }
                    lang={ this.props.lang }
                />
                <Title center>
                    { this.state.pageContent.methods_block_header }
                </Title>
                <HowWeWork
                    methods={ methods }
                    type={ this.props.type }
                />
                <Title center>
                    { this.state.pageContent.purpose_header }
                </Title>
                <WhatWeCan 
                    handleNavigateToServices={ this.handleNavigateToServices } 
                    lang={ this.props.lang }
                />
                <div className={ classes.reviews }>
                    <Title center color={ '#fff' }>
                        { this.state.pageContent.reviews_header }
                    </Title>
                    <Reviews reviews={ this.state.reviews } />
                </div>
                <Contacts
                    title={ this.state.pageContent.contacts_header }
                    description={ this.state.pageContent.contacts_text }
                    social={ this.state.pageContent.contacts_social }
                />
                <CallToAction 
                    title={ this.state.pageContent.call_to_action_header }
                    subtitle={ this.state.pageContent.call_to_action_desc }
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang,
        type: state.basic.type
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);