import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import classes from './Shop.module.scss';
import * as actions from '../../store/actions';
import Product from './Product/Product';
import CallToAction from '../CallToAction/CallToAction';
import ReactGA from 'react-ga';
import Title from '../Title/Title';
import axios from 'axios';

class Shop extends Component {

    state = {
        products: [],
        pageContent: [],
        seo: {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        ReactGA.initialize('UA-157274777-1');
        ReactGA.pageview(window.location.pathname);
        
        axios.get('https://api.engmonsters.in.ua/merch_page/', {
            params: {
                lang: this.props.lang
            }
        })
        .then(response => {
            this.setState({
                pageContent: response.data.texts,
                products: response.data.products,
                seo: response.data.seo
            })
        })
        .catch(error => {
            console.log(error);
        })
    }

    componentDidUpdate(prevProps) {
        if(this.props.lang !== prevProps.lang) {
            axios.get('https://api.engmonsters.in.ua/merch_page/', {
                params: {
                    lang: this.props.lang
                }
            })
            .then(response => {
                this.setState({
                    pageContent: response.data.texts,
                    products: response.data.products,
                    seo: response.data.seo
                })
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    handleBuyButtonClicked = (id) => {
        this.props.showShopModal(id)
    }

    showModal = () => {
        this.props.showModal()
    }

    render() {

        const products = this.state.products.map(product => (
            <Product
                title={ product.name }
                text={ product.desc }
                photo={ product.pic }
                price={ product.price }
                id={ product.id }
                key={ product.id }
                clicked={ () => this.handleBuyButtonClicked(product.id) }
            />
        ))

        return (
            <div className={ classes.shop }>
                <Helmet>
                    <title>{ this.state.seo.seo_title }</title>
                    <meta name="description" content={ this.state.seo.seo_description } />
                </Helmet>
                <div className={ classes.wrapper }>
                    <Title>{ this.state.pageContent.header }</Title>
                    <div className={ classes.products }>
                        { products }
                    </div>
                </div>
                <CallToAction 
                    title={ this.state.pageContent.call_to_action_header }
                    subtitle={ this.state.pageContent.call_to_action_desc }
                    lang={ this.props.lang }
                    showModal={ this.showModal }
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.basic.lang
    }
}

const mapDispatchToProps = dispatch => {
    return{
        showModal: () => dispatch(actions.showModal()),
        showShopModal: (id) => dispatch(actions.showShopModal(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Shop);